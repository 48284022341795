import React, {useEffect, useState} from "react";
import {
  ChangeUserEmail,
  changeUserEmail,
  confirmRegistration,
  getAccessToken,
  getCurrentUser,
  getUserAttributes,
  hasUserCached,
  resendVerificationCode,
} from "./auth-service";

interface AuthContextInterface {
  isAuthenticated: boolean,
  setIsAuthenticated: (hasAuth: boolean) => void,
  authToken: string | null,
  username: string | null,
  userEmail: string | null,
  userEmailVerified: boolean | null,
  changeUserEmail: (args: ChangeUserEmail) => void,
  resendVerification: (email: string) => Promise<void>,
  verifyEmail: (email: string, code: string) => Promise<void>,
}

export const AuthContext = React.createContext<AuthContextInterface>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  authToken: null,
  username: null,
  userEmail: null,
  userEmailVerified: null,
  changeUserEmail: () => {},
  resendVerification: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
});

export const AuthContextProvider: React.FC = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(hasUserCached());
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userEmailVerified, setUserEmailVerified] = useState<boolean | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      setUsername(getCurrentUser()?.getUsername() || null);
      (async () => {
        try {
          const [accessToken, attributes] = await Promise.all([
            getAccessToken(),
            getUserAttributes(),
          ]);
          setAuthToken(accessToken);
          setUsername(attributes.username);
          setUserEmail(attributes.email);
          setUserEmailVerified(attributes.emailVerified);
        } catch (e) {
          if (e instanceof Error) {
            if (e?.name === "UserNotFoundException" ) {
              setUsername(null);
              setAuthToken(null);
            }

            console.error(e);
          }
        }
      })();
    } else {
      setUsername(null);
      setAuthToken(null);
    }
  }, [isAuthenticated]);

  return <AuthContext.Provider value={{
    authToken,
    isAuthenticated,
    username,
    userEmail,
    userEmailVerified,
    setIsAuthenticated: (value) => {
      setIsAuthenticated(value);
    },
    changeUserEmail: (args) => {
      return changeUserEmail(args).then(() => {
        setUserEmail(args.newEmail);
      });
    },
    resendVerification: (email) => {
      return resendVerificationCode(email);
    },
    verifyEmail: (email: string, code: string) => {
      return confirmRegistration(email, code)
        .then(() => setUserEmailVerified(true))
    },
  }}>
    {children}
  </AuthContext.Provider>;
};

export default AuthContext;



