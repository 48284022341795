import React from "react";
import FormField from "../../../ui-components/form/form-field/FormField";
import useDateInputMutator from "../../../ui-components/form/input-helpers/useDateInputMutator";
import useInputValue from "../../../ui-components/form/input-helpers/useInputValue";
import content from "../../../content/content";
import useAlerts from "../../../services/alerts/hooks/useAlerts";
import Form from "../../../ui-components/form/Form";
import useGlobalLoading from "../../../services/loading/hooks/useGlobalLoading";
import {CondensedCol, CondensedGrid, CondensedRow} from "../../../ui-components/grid/condensed-grid/condensed-grid";
import Select from "../../../ui-components/form/inputs/select/Select";
import TripRequestFlightInfo from "./TripRequestFlightInfo";
import useAuth from "../../../services/auth/hooks/useAuth";
import {fetchHelper} from "../../../utl/network/fetch-helper";
import {environmentVariable} from "../../../utl/environment";
import {removeEmpty} from "../../../utl/json/json";
import Button from "../../../ui-components/button/Button";
import FormFieldGroup from "../../../ui-components/form/form-field-group/form-field-group";
import TripEntity from "../../../services/trips/trip-entity";
import useTripRequestModal from "../trip-request-modal/useTripRequestModal";

const passengerOptions = Array(29).fill(undefined).map((v, i) => ({value: i + 1, label: i + 1}));
const sizeOptions = [
  {value: "Light", label: "Light"},
  {value: "Mid", label: "Mid"},
  {value: "Super Mid", label: "Super Mid",},
  {value: "Heavy", label: "Heavy"}
];
const clientTypeOptions = [{value: "Prospect", label: "Prospect"}, {
  value: "Existing Charter",
  label: "Existing Charter",
}, {value: "Jet Card Client", label: "Jet Card Client"}];

interface TripRequestCondensedProps {
  onSuccess?: (trip: TripEntity) => void,
}

const TripRequestCondensed: React.FC<TripRequestCondensedProps> = ({onSuccess}) => {
  const {popError, popSuccess} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading();
  const [fromAirportValue, fromAirportOnChange, setFromAirportValue] = useInputValue();
  const [toAirportValue, toAirportOnChange, setToAirportValue] = useInputValue();
  const [tripDateValue, tripDateRawValue, tripDateOnChange, setTripDateRawValue] = useDateInputMutator();
  const [tripTimeValue, tripTimeOnChange, setTimeValue] = useInputValue();
  const [within, withinOnChange, setWithin] = useInputValue("exact");
  const [sizeValue, sizeOnChange, setSizeValue] = useInputValue();
  const [passengerValue, passengerOnChange, setPassengerValue] = useInputValue();
  const [clientValue, clientOnChange, setClientValue] = useInputValue();
  const {userEmail} = useAuth();
  const present = useTripRequestModal(() => resetForm());

  const resetForm = () => {
    setFromAirportValue("");
    setToAirportValue("");
    setTimeValue("");
    setWithin("");
    setSizeValue("");
    setPassengerValue("");
    setClientValue("");
    setTripDateRawValue("");
  }

  const handleSubmit = async () => {
    const loadingId = startLoading();
    const formData = {
      departure_info: {
        datetime: tripDateValue?.utc(true).hour(
          Number(tripTimeValue.split(":")[0] || "0")
        ).minute(
          Number(tripTimeValue.split(":")[1] || "0")
        ).format(),
        flexibility_in_minutes: 120,
      },
      trip_date: tripDateValue?.format("YYYY-MM-DD"),
      min_aircraft_size: sizeValue,
      passenger_count: passengerValue,
      from_airport: fromAirportValue,
      to_airport: toAirportValue,
      raw_description: "Request made from portal",
      from_contact: userEmail,
      client_type: clientValue,
    };

    try {
      const tripRequestData = await fetchHelper({
        url: `${environmentVariable("REACT_APP_BACKEND_URL")}/trip_request/`,
        method: "POST",
        body: removeEmpty(formData),
        includeAuth: true,
      });

      const trip = new TripEntity(tripRequestData);
      if (onSuccess) {
        onSuccess(trip);
        resetForm();
        present(trip);
      }
    } catch (e) {
      console.warn(e);
      popError("Error Occurred");
    }

    stopLoading(loadingId);
  };

  return <Form className="mb-3" onSubmit={handleSubmit}>
    <CondensedGrid>
      <TripRequestFlightInfo
        fromValue={fromAirportValue}
        fromOnChange={fromAirportOnChange}
        toValue={toAirportValue}
        toOnChange={toAirportOnChange}
        tripDateValue={tripDateRawValue}
        tripDateOnChange={tripDateOnChange}
        tripTimeValue={tripTimeValue}
        tripTimeOnChange={tripTimeOnChange}
        withinValue={within}
        withinOnChange={withinOnChange}
      />
      <CondensedRow className="mt-2">
        <CondensedCol className="d-flex justify-content-center">
          <FormFieldGroup hasLabels={false}>
            <FormField
              formControlProps={{
                as: Select,
                value: sizeValue,
                onChange: sizeOnChange,
                options: sizeOptions,
                required: true,
                placeholder: "Aircraft Size",
              }}
              compressed={true}
            />
            <FormField
              label=""
              formControlProps={{
                as: Select,
                value: passengerValue,
                onChange: passengerOnChange,
                options: passengerOptions,
                required: true,
                placeholder: "# of Passengers",
              }}
              compressed={true}
            />
            <FormField
              formControlProps={{
                as: Select,
                value: clientValue,
                onChange: clientOnChange,
                options: clientTypeOptions,
                required: true,
                placeholder: "Client Type",
              }}
              compressed={true}
            />
          </FormFieldGroup>
        </CondensedCol>
      </CondensedRow>
      <CondensedRow>
        <CondensedCol className="mt-3 d-flex justify-content-center">
          <div className="text-right">
            <Button type="submit" includeArrow={true}>
              {content.formSubmissionText}
            </Button>
          </div>
        </CondensedCol>
      </CondensedRow>
    </CondensedGrid>
  </Form>;
};

export default TripRequestCondensed;
