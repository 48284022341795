import React from "react";
import {Spinner} from "react-bootstrap";
import classNames from "classnames";

interface LoaderProps {
  show: boolean,
  className?: string,
}

const InlineLoader: React.FC<LoaderProps> = ({show, className, children, ...rest}) => {
  const baseClass = "inline-loader";
  const c = classNames([className, `${baseClass}__spinner`]);
  return <div className={classNames([
    baseClass,
    {[`${baseClass}--loading`]: show}
  ])}>
    {children}
    <div className={`${baseClass}__spinner-container`}>
      <Spinner className={c} animation="grow" role="status" {...rest}>
        {show && <span className="visually-hidden">Loading...</span>}
      </Spinner>
    </div>
  </div>;
};

export default InlineLoader;
