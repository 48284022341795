import React, {useEffect, useRef} from "react";
import {InputGroup, InputGroupProps} from "react-bootstrap";
import classNames from "classnames";
import useChildrenAreWrapping from "../../../utl/hooks/useChildrenAreWrapping";

interface FormFieldGroupProps extends InputGroupProps {
  hasLabels: boolean,
  className?: string,
}

const FormFieldGroup: React.FC<FormFieldGroupProps> = ({className, hasLabels, ...rest}) => {
  const {ref, isWrapping} = useChildrenAreWrapping();
  const baseClass = "jw-form-field-group";
  const c = classNames([
    baseClass,
    {[`${baseClass}--has-labels`]: hasLabels},
    {[`${baseClass}--break-up`]: isWrapping},
    className,
  ]);

  return <InputGroup className={c} {...rest} ref={ref}/>;
};

export default FormFieldGroup;
