import React, {ReactNode} from "react";
import classNames from "classnames";

interface InfoProps {
  label: string,
  className?: string,
  children: ReactNode,
  alignment?: "left" | "center" | "right",
}

const Info: React.FC<InfoProps> = ({label, alignment, className, children, ...rest}) => {
  const c = classNames([
    "jw-info",
    {[`jw-info--${alignment}`]: alignment},
    className
  ]);
  return <div className={c} {...rest}>
    <b className="jw-info__label">{label}:</b>
    <div className="jw-info__content">{children}</div>
  </div>;
};

export default Info;
