/*
  useAirportOptions will return options that work with react-select. Here's information about
  react-select: https://react-select.com/home
*/
import getAirportOptions from "../../../../utl/airports/get-airport-options";
import {useEffect, useMemo, useState} from "react";
import useAlerts from "../../../../services/alerts/hooks/useAlerts";
import Fuse from "fuse.js";
import debounce from "lodash/debounce";

type AirportOption = { label: string, value: string };
export type AirportOptions = AirportOption[];

const useAirportOptions = (selectValue = "") => {
  const [options, setOptions] = useState<AirportOptions>([]);
  const [sortedOptions, setSortedOptions] = useState<AirportOptions>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {popError} = useAlerts();
  const firstLetter = selectValue[0];
  const secondLetter = selectValue[1];

  const debouncedChangeHandler = useMemo(
  () => debounce(() => {
    if (options) {
      const fuse: Fuse<AirportOption> = new Fuse(options, {
        keys: ['label']
      });

      setSortedOptions(fuse.search(selectValue).map(f => f.item));
    }
  }, 300)
  , [options]);

  useEffect(() => {
    debouncedChangeHandler();
  }, [options, selectValue])

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const airportOptions = await getAirportOptions(firstLetter, secondLetter);
        setOptions(airportOptions);
      } catch (e) {
        if (e instanceof Error)
          popError(e.message);
      }
      setIsLoading(false);
    })()
  }, [firstLetter, secondLetter, popError]);

  return {
    options: selectValue.length > 2 ? sortedOptions: options,
    isLoading,
  };
};

export default useAirportOptions;
