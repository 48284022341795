import React from "react";
import {ListGroup} from "react-bootstrap";
import classNames from "classnames";

interface ListPillItemProps {
  className?: string,
}

const ListPillItem: React.FC<ListPillItemProps> = ({className, ...rest}) => {
  const c = classNames([
    "jw-list-pill__item",
    className,
  ]);

  return <ListGroup.Item className={c} {...rest} />;
};

export default ListPillItem;
