import React, {useContext, useEffect} from "react";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import classNames from "classnames";
import {useHistory} from "react-router-dom";
import useLoadTrips from "../../services/trips/hooks/useLoadTrips";
import {TripListKey} from "../../services/trips/trips-service";
import ListLoading from "../../ui-components/list/ListLoading";
import ListError from "../../ui-components/list/ListError";
import usePrevious from "../../utl/hooks/usePrevious";
import TripMatchListItem from "./TripMatchListItem";
import {TripMatchesSearchContext} from "./trip-matches-search/trip-matches-search-context";
import {getTripRequestPageForId} from "../../pages/portal/TripRequestPage";
import Button from "../../ui-components/button/Button";
import {TRIP_QUERY_LIMIT} from "../../config";

// TODO: Props of trips array/ trip objects
interface TripMatchListProps {
  tripListKey: TripListKey,
  triggerRefresh?: string | number, // when this changes trip will be refreshed
  className?: string,
}

const TripMatchList: React.FC<TripMatchListProps> = ({
  className,
  tripListKey,
  triggerRefresh,
  ...rest
}) => {
  const {isLoading, trips, error, setSearchTerm, refreshTrips, loadMore, moreAvailable} = useLoadTrips(tripListKey);
  const c = classNames([className]);
  const previousTriggerRefresh = usePrevious(triggerRefresh);
  const searchTerm = useContext(TripMatchesSearchContext);
  const history = useHistory();


  useEffect(() => {
    if (
      triggerRefresh &&
      previousTriggerRefresh !== triggerRefresh
    ) {
      refreshTrips();
    }
  }, [triggerRefresh]);

  useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm])

  return <>
    <div className={c} {...rest}>
      <ListGroup
        className="trip-match-list">
        {/* If there are no trips */}
        {trips.length === 0 && (
          <ListGroupItem>No trips</ListGroupItem>
        )}
        {/* If there are trips */}
        {trips.length > 0 && (
          <ListGroupItem className="trip-match-list__legend">
            <div className="trip-field">Flight</div>
            <div className="trip-field">Flight Date</div>
            <div className="trip-field">Aircraft</div>
            <div className="trip-field">Status</div>
            <div className="trip-field"/>
          </ListGroupItem>
        )}
        {trips.map((trip) => (
          <TripMatchListItem
            key={trip.gsi1sk}
            trip={trip}
            onClick={(trip) => history.push(getTripRequestPageForId(trip.dynamoFromAirport, trip.dynamoCompositeSort))}
          />
        ))}
        {error && <ListError/>}
        <ListLoading show={isLoading}/>
        {trips && <>
          <div className="mt-3 d-flex justify-content-center">
            {(moreAvailable || trips.length > TRIP_QUERY_LIMIT) && (
              <Button onClick={() => loadMore()} disabled={!moreAvailable}>Load More</Button>
            )}
          </div>
        </>}
      </ListGroup>
    </div>
  </>;
};

export default TripMatchList;
