import React from "react";
import {Tabs as BoostrapTabs, TabsProps as BoostrapTabsProps} from "react-bootstrap";
import classNames from "classnames";

interface TabsProps extends BoostrapTabsProps {
  className?: string,
}

const TabsContainer: React.FC<TabsProps> = ({className, ...rest}) => {
  const c = classNames("jw-tabs", className);
  return <BoostrapTabs className={c} {...rest} />;
};

export default TabsContainer;
