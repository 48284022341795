export const environmentVariable: (variable: string) => string = (variable: string) => {
  if (!process.env[variable]) {
    throw new Error(`Environment variable ${variable} is not configured.`);
  }

  return process.env[variable] || '';
};

export const getHost = () => {
  return window.location.protocol + "//" + window.location.host;
}
