import {useContext} from "react";
import ModalStackContext from "../context/modal-stack-context";
import {ModalIdContext} from "../context/modal-id-context";

/*
Pass through hook to loading modal functionality.
Modal to be affected defaults to the current context.
 */
const useLoadingModal = () => {
  const modalId = useContext(ModalIdContext);
  const {setModalLoading} = useContext(ModalStackContext);
  return (isLoading: boolean) => {
    setModalLoading(modalId, isLoading);
  };
};

export default useLoadingModal;
