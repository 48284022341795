import React, {useState} from "react";
import uniqueId from "lodash/uniqueId";
import FullScreenLoader from "../../ui-components/loaders/full-screen-loader/full-screen-loader";

export interface LoadingContextInterface {
  startLoading: () => string,
  stopLoading: (id: string) => void,
}

export const LoadingContext = React.createContext<LoadingContextInterface>({
  startLoading: () => '',
  stopLoading: () => {},
});

export const LoadingContextProvider: React.FC = ({children}) => {
  const [loaders, setLoaders] = useState<string[]>([]);
  const isLoading = loaders.length > 0;

  const startLoading = () => {
    const loaderId = uniqueId("loader-");

    setLoaders(currentState => {
      return [
        ...currentState,
        loaderId,
      ];
    });

    return loaderId;
  };

  const stopLoading = (loaderId: string) => {
    setLoaders(currentState => currentState.filter(l => l !== loaderId));
  };

  return <LoadingContext.Provider value={{
    startLoading,
    stopLoading,
  }}>
    {children}
    <FullScreenLoader show={isLoading} />
  </LoadingContext.Provider>;
};

export default LoadingContext;



