import {getAccessToken} from "../../services/auth/auth-service";

interface fetchHelperArgs {
  url: string,
  method: "GET" | "POST" | "PUT",
  body?: { [key: string]: string },
  // custom functionality
  includeAuth: boolean,
  params?: Record<string, string>
}

export const fetchHelper = async ({
  url,
  method,
  body,
  includeAuth,
  params,
}: fetchHelperArgs) => {
  const urlInstance = new URL(url);
  const authToken = includeAuth && await getAccessToken();

  let headers: HeadersInit = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  if (includeAuth) {
    if (authToken)
      headers.Authorization = authToken;
    else
      throw new Error("Can not fetch. No authorization token.");
  }

  let finalUrl = urlInstance.toString();
  if (params) {
    finalUrl += "?" + new URLSearchParams(params).toString();
  }

  const response = await fetch(finalUrl, {
    mode: "cors",
    credentials: "include",
    method,
    headers,
    body: JSON.stringify(body),
  });

  try {
    const result = await response.json();
    if (response.status >= 200 && response.status < 300) {
      return result;
    } else {
      if (result.error) {
        return Promise.reject(
          new Error(String(result.error)),
        );
      } else {
        return Promise.reject(new Error("An error occurred"));
      }
    }
  } catch (e) {
    console.warn(e);
    return Promise.reject(new Error("An error occurred"));
  }
};
