import React, {useEffect, useMemo, useState} from "react";
import useAirportOptions from "./useAirportOptions";
import Select, {SelectProps} from "../select/Select";
import {formatAirportOptionLabel} from "../../../../utl/airports/get-airport-options";

interface AirportSelectProps extends Omit<SelectProps, "options"> {
}

const AirportSelect: React.FC<AirportSelectProps> = ({value, defaultValue, ...rest}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const {options, isLoading} = useAirportOptions(inputValue);
  const [valueWithOption, setValueWithOption] = useState<{ label: string, value: string } | undefined>(undefined);
  const optionsWithDefault = useMemo(() => {
    const o = options || [];
    // If there's not an option for the default value add it
    if (defaultValue && !options.find(o => o.value === defaultValue.value)) {
      o.push(defaultValue);
    }

    // If there's not an option for the current selectedValue add it
    if (valueWithOption && !options.find(o => o.value === valueWithOption.value)) {
      o.push(valueWithOption);
    }

    // If there's not an option for the current value add it
    if (value && !options.find(o => o.value === value)) {
      o.push({value, label: value});
    }


    return o;
  }, [options, defaultValue]);

  /*
    Default value should be value or fallback to the defaultValue prop. If there's no options that match the value
    prop create an option with the value to preserve it. Falling back to options creation is for options that
    aren't known when the component is initialized.
  */
  useEffect(() => {
    const v = value || defaultValue?.value;
    setValueWithOption(v ? {value: v, label: optionsWithDefault.find(o => o.value === v)?.label || v} : undefined);
  }, [defaultValue, value]);

  return <Select
    options={optionsWithDefault}
    isLoading={isLoading}
    onInputChange={(v) => setInputValue(v)}
    value={valueWithOption?.value || ""}
    formatOptionLabel={(o) => formatAirportOptionLabel(o)}
    {...rest}
  />;
};

export default AirportSelect;
