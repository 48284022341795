import React, {useContext} from "react";
import ModalStackContext from "../context/modal-stack-context";

interface useModalOptions {
  onDismiss?: () => void,
  preventHide?: boolean,
}

const usePresentModal = (modalContent: React.ReactNode, options: useModalOptions = {}) => {
  const {presentModal} = useContext(ModalStackContext);

  return () => presentModal({
    children: modalContent,
    ...options,
  });
};

export default usePresentModal;
