import React, {FormEvent, useState} from "react";
import {Form as BootstrapForm, FormProps} from "react-bootstrap";
import classNames from "classnames";

/*
Form a wrapper over Boostrap to set it up with default behavior like
preventing default and setting the validation state on the form.
 */
const Form: React.FC<FormProps> = ({onSubmit, className, children, ...rest}) => {
  const [validated, setValidated] = useState(false);
  const c = classNames(['jw-form', className]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else if (onSubmit) {
      onSubmit(e);
    }
  };

  return <BootstrapForm
    className={c}
    noValidate
    validated={validated}
    onSubmit={handleSubmit}
    {...rest}
  >
    {children}
  </BootstrapForm>;
};

export default Form;
