import React, {useEffect, useRef, useState} from "react";
import {Alert, AlertProps} from "react-bootstrap";
import classNames from "classnames";
import {CSSTransition} from "react-transition-group";
import {ALERTS_DISMISS_AFTER_MS} from "../../config";

export interface AlertRawTransitionProps {
  onExited?: () => void,
}

const AlertBase: React.FC<AlertRawTransitionProps & AlertProps> = ({
  onExited,
  variant = "success",
  className,
  ...rest
}) => {
  const [show, setShow] = useState<boolean>(false);
  const baseClass = "alert-message";
  const c = classNames([
    baseClass,
    className,
  ]);
  const nodeRef = useRef(null);

  useEffect(() => {
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, ALERTS_DISMISS_AFTER_MS);
  }, []);

  return <CSSTransition
    in={show}
    timeout={500} // Should match transition in CSS
    classNames={c}
    onExited={onExited}
    mountOnEnter
    nodeRef={nodeRef}
  >
    <Alert className={c} variant={variant} {...rest} ref={nodeRef}/>
  </CSSTransition>;
};

export default AlertBase;
