import React from "react";
import classNames from "classnames";
import {Container, Row, Col, ColProps, RowProps} from "react-bootstrap";

interface ClassNameProps {
  className?: string,
}

const baseClass = "jw-condensed-grid";

/* A Boostrap Container that sets up a specific class name and functionality. To be used with <CondensedRow /> and <CondensedCol /> */
export const CondensedGrid: React.FC<ClassNameProps> = ({className, ...rest}) => {
  const c = classNames([baseClass, className])
  return <Container className={c} {...rest} />;
};

export const CondensedRow: React.FC<ClassNameProps & RowProps> = ({className, ...rest}) => {
  const c = classNames([`${baseClass}__row`, className])
  return <Row className={c} {...rest} />;
};

export const CondensedCol: React.FC<ClassNameProps & ColProps> = ({className, ...rest}) => {
  const c = classNames([`${baseClass}__col`, className])
  return <Col className={c} {...rest} />;
};
