import React, {useEffect, useRef} from "react";
import classNames from "classnames";

interface DummySelectProps {
  onChange: React.ChangeEventHandler<HTMLSelectElement>,
  value: string,
  required?: boolean,
  className?: string,
}

/*
Dummy select is a hidden input used to create an real
browser on change event. It should be used in conjunction with selects
that don't render an actual <select /> element.
 */
const DummySelect: React.FC<DummySelectProps> = ({value, required, className, ...rest}) => {
  const c = classNames(["jw-dummy-select", className])
  const naitiveSelectRef = useRef<HTMLSelectElement>(null);

  const handleValueChange = (value: string) => {
    if (naitiveSelectRef?.current) {
      naitiveSelectRef.current.value = value;
      const event = new Event("change", {
        bubbles: true,
      });
      naitiveSelectRef.current.dispatchEvent(event);
    }
  };

  useEffect(() => {
    handleValueChange(value);
  }, [value]);

  return <select className={c} {...rest} ref={naitiveSelectRef} required={required}>
    <option/>
    {value && (
      <option label={value}>{value}</option>
    )}
  </select>;
};

export default DummySelect;
