import {Button as BootstrapButton, ButtonProps as BootstrapButtonProps} from "react-bootstrap";
import {ChevronRight} from "react-bootstrap-icons";
import classNames from "classnames";

export interface ButtonProps extends BootstrapButtonProps {
  includeArrow?: Boolean,
  isBackButton?: Boolean,
}

const Button: React.FC<ButtonProps> = ({className, isBackButton, includeArrow, variant = "dark", children, ...rest}) => {
  const c = classNames([
    "jw-button",
    {"jw-button--back": isBackButton},
    className,
  ])
  return <BootstrapButton className={c} variant={variant} {...rest}>
    {children}
    {(includeArrow || isBackButton) && <ChevronRight className="jw-button__arrow" />}
  </BootstrapButton>
}

export default Button;
