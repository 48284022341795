import React, {useEffect, useRef, useState} from "react";
import ReactSelect from "react-select";
import DummySelect from "./dummy-select/DummySelect";
import classNames from "classnames";

export type OptionType = { value: string, label: string }
export type OptionsType = OptionType[]

export interface SelectProps {
  options: OptionsType,
  onChange: React.ChangeEventHandler<HTMLSelectElement>,
  isLoading?: boolean,
  onInputChange?: (inputValue: string) => void,
  required?: boolean,
  className?: string,
  defaultValue?: { value: string, label: string },
  value?: string, // used to send warning to console and prevent value from being passed to select since it's a uncontrolled component.
  formatOptionLabel?: (o: OptionType) => string,
}

/*
Based off of react-select though has more limited functionality.
See https://react-select.com/home for more information about ReactSelect
*/
const Select: React.FC<SelectProps> = ({
  defaultValue,
  value,
  onChange,
  required,
  className,
  isLoading,
  formatOptionLabel,
  options,
  ...rest
}) => {
  const [reactSelectValue, setReactSelectValue] = useState<string>(defaultValue?.value || "");
  const baseClass = "jw-select";
  const c = classNames([baseClass, className]);
  const ref = useRef<any>();

  useEffect(() => {
    if (ref.current && defaultValue) {
      ref.current.setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!value) {
      setReactSelectValue(""); // required for validation when value is reset
    }
  }, [value]);

  return <>
    <DummySelect
      onChange={onChange}
      value={reactSelectValue}
      required={required}
    />
    <ReactSelect
      ref={ref}
      className={c}
      classNamePrefix="jw-select"
      onChange={(v) => {
        setReactSelectValue(v?.value || "")
      }}
      menuPortalTarget={document.body}
      styles={{menuPortal: base => ({...base, zIndex: 9999})}}
      defaultValue={defaultValue}
      value={value === undefined ? undefined : options.find(o => o.value.toString() === value?.toString()) || null}
      isLoading={isLoading}
      formatOptionLabel={formatOptionLabel}
      options={options}
      {...rest}
    />
  </>;
};

export default Select;
