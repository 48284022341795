import {useEffect, useRef, useState} from "react";
import useWindowSize from "./useWindowSize";

const useChildrenAreWrapping = () => {
  const windowSize = useWindowSize();
  const elementRef = useRef<HTMLElement>(null);
  const [isWrapping, setIsWrapping] = useState<Boolean>(false);
  const [lastKnownNonWrappingValue, setLastKnownNonWrappingValue] = useState<number>();


  useEffect(() => {
    let offsetTop: number;
    let fieldsAreWrapping = false;
    elementRef.current?.childNodes.forEach(child => {
      if (child.nodeType === 1) { // is element node
        // @ts-ignore
        const childOffsetTop = child.offsetTop;

        if (offsetTop === undefined) {
          // @ts-ignore
          offsetTop = child.offsetTop;
        } else if (childOffsetTop !== offsetTop) {
          fieldsAreWrapping = true;
        }
      }
    });

    setIsWrapping(fieldsAreWrapping);

    if (!fieldsAreWrapping) {
      setLastKnownNonWrappingValue(windowSize.width);
    }
  }, [elementRef, windowSize, isWrapping]);

  useEffect(() => {
    if (
      lastKnownNonWrappingValue !== undefined &&
      windowSize.width >= lastKnownNonWrappingValue
    ) {
      setIsWrapping(false);
    }

  }, [windowSize, lastKnownNonWrappingValue]);

  return {
    ref: elementRef,
    isWrapping,
  };
};

export default useChildrenAreWrapping;
