import React, {useState} from "react";
import parsePhoneNumberFromString from "libphonenumber-js";

const usePhoneInputMutator = (defaultValue?: string) => {
  const [rawValue, setRawValue] = useState<string>(defaultValue || "");

  const onChange: React.ChangeEventHandler =
    // @ts-ignore
    (e) => setRawValue(e.target.value);

  return [
    parsePhoneNumberFromString(rawValue, "US") || null,
    rawValue,
    onChange,
    setRawValue,
  ] as const;
};

export default usePhoneInputMutator;
