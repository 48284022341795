import {fetchHelper} from "../../utl/network/fetch-helper";
import {environmentVariable} from "../../utl/environment";
import TripEntity from "./trip-entity";
import MatchEntity from "./match-entity";
import {TRIP_QUERY_LIMIT} from "../../config";

export type TripStatus = "matched" | "unmatched"; // these are placeholder statuses for now

export type TripQueryRange = "future" | "past"; // these are placeholder statuses for now

export type TripListKey = "pending" | "matched" | "confirmed" | "past";

type TripData = Record<string, string>

const getQueryParamsForTripList: (key: TripListKey) => { status?: TripStatus, range?: TripQueryRange } = (key: TripListKey) => {
  if (key === "pending")
    return {
      status: "unmatched",
      range: "future",
    };

  if (key === "matched") {
    return {
      status: "matched",
      range: "future",
    };
  }

  if (key === "confirmed") {
    return {
      status: "matched",
      range: "future",
    };
  }

  if (key === "past") {
    return {
      range: "past",
    };
  }

  throw new Error(`getQueryParamsForTripList key ${key} has not been implemented.`);
};

export const getTrip = async (fromAirport: string, compositeSort: string) => {
  const tripData = await fetchHelper({
    url: `${environmentVariable("REACT_APP_BACKEND_URL")}/trip_request/${fromAirport}/${compositeSort}`,
    method: "GET",
    includeAuth: true,
  });

  return new TripEntity(tripData)
};


interface getTripsArgs {
  key: TripListKey,
  limit?: number,
  lastEvaluatedKey?: string|null,
}
export const getTrips: (args: getTripsArgs) => Promise<{ trips: TripEntity[], lastEvaluatedKey: string | null}> = async ({key, limit = TRIP_QUERY_LIMIT, lastEvaluatedKey}) => {
  let conditionalArgs: Record<string, any> = {};

  let params: Record<string, any> = {...getQueryParamsForTripList(key), limit};
  if (lastEvaluatedKey) {
    params["lastEvaluatedKey"] = lastEvaluatedKey;
  }
  if (Object.keys(params).length > 0) {
    conditionalArgs.params = params;
  }

  const {trips, lastEvaluatedKey: last} = await fetchHelper({
    url: `${environmentVariable("REACT_APP_BACKEND_URL")}/trips/`,
    method: "GET",
    includeAuth: true,
    ...conditionalArgs,
  });
  return {
    trips: trips.map((d: TripData) => new TripEntity(d)),
    lastEvaluatedKey: last && JSON.stringify(last),
  };
};

export const getMatch: (trip: TripEntity) => Promise<any> = async (trip) => {
  const matchData = await fetchHelper({
    url: `${environmentVariable("REACT_APP_BACKEND_URL")}/match/${encodeURIComponent(trip.matchId)}`,
    method: "GET",
    includeAuth: true,
  });

  return new MatchEntity(matchData, trip);
};
