import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import {environmentVariable} from "../../utl/environment";
import {getCurrentUserProfile, saveUserProfile} from "../user-profile/user-profile-service";
import {UserProfile} from "../user-profile/type";
import {clearValue, getStoredValue, storeValue} from "../../utl/storage";
import {SignUpFormData} from "../../app/account/sign-up/SignUpForm";

const SIGN_UP_PROFILE_STORAGE_KEY = "signUpProfile";

const cognitoPool = new CognitoUserPool({
  UserPoolId: environmentVariable("REACT_APP_AUTH_USER_POOL_ID"),
  ClientId: environmentVariable("REACT_APP_AUTH_CLIENT_ID"),
});

export const signUp = (email: string, password: string, userProfile: SignUpFormData) => {
  return new Promise<CognitoUser>((resolve, reject) => {
    // Store user profile in browser storage to be saved after login
    storeValue(SIGN_UP_PROFILE_STORAGE_KEY, JSON.stringify(userProfile));

    cognitoPool.signUp(email, password, [], [], function (
      err,
      result,
    ) {
      if (err) {
        reject(err);
        return;
      }
      const cognitoUser = result?.user;
      if (!cognitoUser) {
        reject(new Error("Sign up did not generate a user correctly."));
      } else {
        resolve(cognitoUser);
      }
    });
  });
};

interface UserAndSession {
  session: CognitoUserSession,
  user: CognitoUser,
}


export const login: (email: string, password: string) => Promise<UserAndSession> = (email, password) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (cognitoUserSession) => {
        try {
          const profileString = getStoredValue(SIGN_UP_PROFILE_STORAGE_KEY)
          if (profileString) {
            const profile: SignUpFormData = JSON.parse(profileString);
            const user = getCurrentUser();
            if (user) {
              saveUserProfile({
                ...profile,
                username: user.getUsername(),
              }).then(userProfile => {
                console.log("Sign up information written.");
                clearValue(SIGN_UP_PROFILE_STORAGE_KEY);
              });
            }
          }
        } catch (e) {
          console.warn(e);
        }

        resolve({
          session: cognitoUserSession,
          user: cognitoUser,
        });
      },
      onFailure: (e) => reject(e),
      newPasswordRequired: (data) => {
        console.log("newPasswordRequired", data);
        reject(new Error("New Password Required functionality needs to be implemented"));
      },
    });
  });
};

export const logout = () => {
  const cognitoUser = cognitoPool.getCurrentUser();
  if (cognitoUser !== null) {
    cognitoUser.signOut();
  }
};

export const getCurrentUser: () => CognitoUser | null = () => {
  return cognitoPool.getCurrentUser();
}

export const hasUserCached = () => {
  return !!cognitoPool.getCurrentUser();
};

export const getUserAndSession: () => Promise<UserAndSession> = () => {
  return new Promise((resolve, reject) => {
      const user = getCurrentUser();

      if (user) {
        user.getSession(function (err: any, session: CognitoUserSession) {
          if (err) {
            reject(err);
          } else {
            resolve({
              user,
              session,
            });
          }
        });
      } else {
        reject(new Error("No current user"));
      }
  });
};

export const getAccessToken = async () => {
  const {session} = await getUserAndSession();
  return session.getAccessToken().getJwtToken() || null;
};

export interface ChangeUserEmail {
  currentEmail: string,
  newEmail: string,
  confirmPassword: string,
}

export const changeUserEmail = (args: ChangeUserEmail) => {
  return new Promise(async (resolve, reject) => {
    const {currentEmail, newEmail, confirmPassword} = args;

    // Confirm user knows password by re-logging in
    try {
      const {user} = await login(currentEmail, confirmPassword);

      // Change user's email
      user.updateAttributes(
        [new CognitoUserAttribute({
          Name: "email",
          Value: newEmail,
        })],
        (err, results) => {
          if (err) {
            reject(err);
          } else {
            getCurrentUserProfile().then(userProfile => {
              return saveUserProfile({
                ...userProfile,
                email: newEmail
              })
            })
            .then(() => {
              resolve(newEmail);
            })
            .catch(() => {
              reject(new Error("Changed email successfully but could not update profile data."));
            });
          }
        });
    } catch (e) {
      reject(e);
    }
  });
};

interface changeUserPasswordArgs {
  oldPassword: string,
  newPassword: string,
}

export const changeUserPassword = (args: changeUserPasswordArgs) => {
  const {oldPassword, newPassword} = args;

  return new Promise<void>(async (resolve, reject) => {
    const {user} = await getUserAndSession();
    user.changePassword(oldPassword, newPassword, (err, success) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
};

export interface UserAttributes {
  username: string,
  email: string,
  emailVerified: boolean,
}

export const getUserAttributes: () => Promise<UserAttributes> = async () => {
  const pluckUserAttributes = (attributes: CognitoUserAttribute[], name: string) => {
    return attributes?.find(a => a.Name === name)?.Value;
  };

  return new Promise(async (resolve, reject) => {
    try {
      const {user} = await getUserAndSession();
      user?.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else if (!attributes) {
          reject(new Error("User has no attributes"));
        } else {
          const email = pluckUserAttributes(attributes, "email");
          const emailVerified = pluckUserAttributes(attributes, "email_verified") === "true";
          if (email)
            resolve({
              username: user.getUsername(),
              email,
              emailVerified,
            });
          else
            reject(new Error("User does not have an email address."));
        }
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const forgotPassword = (email: string) => {
  return new Promise<void>((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool,
    });

    // heads up: forgot password only sends emails to accounts that have been verified
    cognitoUser.forgotPassword({
      onSuccess: (s) => {
        resolve();
      },
      onFailure: (e) => reject(e),
    });
  });
};

export const changeForgottenPassword = (email: string, verificationCode: string, newPassword: string) => {
  return new Promise<void>((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoPool,
    });

    // heads up: forgot password only sends emails to accounts that have been verified
    cognitoUser.confirmPassword(
      verificationCode,
      newPassword,
      {
        onSuccess: (s) => {
          resolve();
        },
        onFailure: (e) => reject(e),
      });
  });
};

export const resendVerificationCode = (email: string) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoPool,
  });

  return new Promise<void>((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err) => {
      if (err) {
        reject(err);
        return;
      }
      resolve();
    });
  });
};

export const confirmRegistration = (email: string, code: string) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoPool,
  });

  return new Promise<void>((resolve, reject) => {
    cognitoUser.confirmRegistration(code, false, (err) => {
      if (err) {
        reject(err);
        return;
      }
      resolve();
    });

  });
};
