import React from "react";
import {RouteProps, useHistory} from "react-router-dom";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import Form from "../../ui-components/form/Form";
import FormField from "../../ui-components/form/form-field/FormField";
import {Row, Col} from "react-bootstrap";
import useInputValue from "../../ui-components/form/input-helpers/useInputValue";
import useAlerts from "../../services/alerts/hooks/useAlerts";
import useGlobalLoading from "../../services/loading/hooks/useGlobalLoading";
import {forgotPassword} from "../../services/auth/auth-service";
import {ForgotPasswordSecondPageRoute} from "./ForgotPasswordSecondPage";
import CardContainer from "../../ui-components/card-container/CardContainer";
import Button from "../../ui-components/button/Button";

/* This route must be configured in aws cognito login redirect */
export const ForgotPasswordPageRoute = "/forgot-password";

const Page: React.FC = () => {
  const [email, setEmail] = useInputValue("");
  const {popError} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading();
  let history = useHistory();

  const handleSubmit = async () => {
    const loadingID = startLoading();
    try {
      await forgotPassword(email);
      history.push(ForgotPasswordSecondPageRoute, {
        email,
      });
    } catch (e) {
      if (e instanceof Error)
        popError(e.message);
    }
    stopLoading(loadingID);
  };

  return <RouteGuardSwitcher routeGuard="landing">
    <PageLayout
      navType="landing"
      pageWidth="form"
    >
      <CardContainer type="white">
        <h1>Reset Your Password.</h1>
        <Form onSubmit={handleSubmit}>
          <FormField
            label="Email"
            formControlProps={{
              type: "email",
              value: email,
              onChange: setEmail,
              required: true,
            }}
          />
          <Row>
            <Col className="d-flex justify-content-end">
              <Button className="mt-2" type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </CardContainer>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const ForgotPasswordPage: RouteProps = {
  component: Page,
  path: ForgotPasswordPageRoute,
  exact: true,
};

export default ForgotPasswordPage;
