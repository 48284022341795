import {useEffect, useState} from "react";
import {getMatch} from "../trips-service";
import MatchEntity from "../match-entity";
import useAlerts from "../../alerts/hooks/useAlerts";
import TripEntity from "../trip-entity";

const useLoadMatch = (trip: TripEntity | null) => {
  const [loadingMatchId, setLoadingMatchId] = useState<string | undefined>(trip?.matchId)
  const [match, setMatch] = useState<MatchEntity | null>(null)
  const {popError} = useAlerts();

  const handleLoadMatch = async () => {
    if (trip?.matchId) {
      setLoadingMatchId(trip?.matchId)
      try {
        const match = await getMatch(trip)
        // Set match if request isn't stale
        setMatch(match)
      } catch (e) {
        if (e instanceof Error)
          popError(e.message);
      }

      setLoadingMatchId(currentId => {
        if (currentId === trip?.matchId)
          return undefined;
        else return currentId;
      });
    } else {
      setLoadingMatchId(undefined)
    }
  }

  useEffect(() => {
    handleLoadMatch();
  }, [trip?.matchId])

  return {
    loading: Boolean(loadingMatchId),
    hasMatch: Boolean(trip?.matchId),
    match: match,
  }
}

export default useLoadMatch;
