import React from "react";
import classNames from "classnames";

interface InfoContainerProps {
  className?: string,
}

const InfoContainer: React.FC<InfoContainerProps> = ({className, ...rest}) => {
  const c = classNames(["jw-info-container", className])
  return <div className={c} {...rest} />;
};

export default InfoContainer;
