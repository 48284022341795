import React from "react";
import useRedirectOnAuth from "../../../services/auth/hooks/useRedirectOnAuth";
import {AUTENTICATED_LANDING_PAGE} from "../../app-global/App";

const LandingRouteGuard: React.FC = ({children}) => {
  useRedirectOnAuth(AUTENTICATED_LANDING_PAGE, "AUTHENTICATED");
  return <>
    {children}
  </>;
};

export default LandingRouteGuard;
