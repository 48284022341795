import React, {useContext, useEffect, useState} from "react";
import useLoadUserProfile from "./useLoadUserProfile";
import {UserProfile} from "../../../services/user-profile/type";
import useAlerts from "../../../services/alerts/hooks/useAlerts";
import {saveUserProfile} from "../../../services/user-profile/user-profile-service";
import useGlobalLoading from "../../../services/loading/hooks/useGlobalLoading";
import AuthContext from "../../../services/auth/auth-context";
import EditProfileForm, {EditProfileFormData} from "./EditProfileForm";

const EditProfileInfo: React.FC = ({...rest}) => {
  const {userEmail} = useContext(AuthContext)
  const userProfile = useLoadUserProfile();
  const {popError, popSuccess} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading();
  const [defaultEditProfileData, setDefaultEditProfileData] = useState<EditProfileFormData>();

  useEffect(() => {
    if (userProfile) {
      resetFormWithNewProfile(userProfile);
    }
  }, [userProfile]);

  const handleSubmit = async (data: EditProfileFormData) => {
    if (userProfile && userEmail) {
      const loaderId = startLoading();
      try {
        const profile = await saveUserProfile({
          ...userProfile,
          first: data.first,
          last: data.last,
          companyName: data.companyName,
          phone: data.phone,
          operatingTimezone: data.operatingTimezone,
          email: userEmail, // prevents email from being overwritten if it is changed.
        });
        resetFormWithNewProfile(profile);
        popSuccess("Profile updated successfully");
      }
      catch (e) {
        if (e instanceof Error)
          popError(e.message);
      }
      stopLoading(loaderId);
    } else {
      popError("User profile can not be saved. Profile is not loaded yet.");
    }
  };

  const resetFormWithNewProfile = (userProfile: UserProfile) => {
    setDefaultEditProfileData({
      first: userProfile.first,
      last: userProfile.last,
      companyName: userProfile.companyName,
      phone: userProfile.phone,
      operatingTimezone: userProfile.operatingTimezone,
    })
  };

  return <div {...rest}>
    <EditProfileForm
      defaultValues={defaultEditProfileData}
      onSubmit={handleSubmit}
    />
  </div>;
};

export default EditProfileInfo;
