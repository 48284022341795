import {getCurrentUser} from "../auth/auth-service";
import {fetchHelper} from "../../utl/network/fetch-helper";
import {environmentVariable} from "../../utl/environment";

interface UserProfile {
  username: string,
  email: string,
  first: string,
  last: string,
  phone?: string,
  companyName?: string,
  operatingTimezone?: string,
  userType?: string,
}

export const getCurrentUserProfile: () => Promise<UserProfile> = async () => {
  const user = await getCurrentUser();
  const username = user?.getUsername();

  if (!user) {
    throw new Error("User is not authenticated.");
  }

  return await fetchHelper({
    url: `${environmentVariable("REACT_APP_BACKEND_URL")}/user?username=${username}`,
    method: "GET",
    includeAuth: true,
  });
};

export const saveUserProfile: (userProfile: UserProfile) => Promise<UserProfile> = async (
  userProfile
) => {
  const userData = userProfile as unknown as Record<string, string>;
  return await fetchHelper({
    url: `${environmentVariable("REACT_APP_BACKEND_URL")}/user`,
    method: "POST",
    body: userData,
    includeAuth: true,
  });
}
