import React from "react";
import InlineLoader from "../loaders/inline-loader/InlineLoader";

interface ComponentProps {
  show: boolean,
}

const ListLoading: React.FC<ComponentProps> = ({show, ...rest}) => {
  return show ? <div>
    <InlineLoader show={show} />
  </div> : null;
};

export default ListLoading;
