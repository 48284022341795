import React from "react";
import {RouteProps} from "react-router-dom";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import TripMatchList from "../../app/trip-matches/TripMatchList";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import CardContainer from "../../ui-components/card-container/CardContainer";
import Tab from "../../ui-components/tabs/Tab";
import TabsContainer from "../../ui-components/tabs/TabsContainer";

export const PastTripRequestsPageRoute = "/past-trips";

/* TODO, when we have support for pending/confirmed trip requests
   use tab components and two separate TripMatchList components:
   <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
     <Tab eventKey="home" title="Confirmed">
       <TripMatchList />
     </Tab>
     <Tab eventKey="profile" title="Unconfirmed">
       <TripMatchList />
     </Tab>
   </Tabs>
*/

const Page: React.FC = () => {
  return <RouteGuardSwitcher routeGuard="portal">
    <PageLayout
      navType="app"
      heroType="default"
      cardContainerSlot={<>
        <h1>Past Trip Requests</h1>
        <p className="mb-5 text-center">This is an archive of your previous trip requests.</p>
        <TabsContainer
          defaultActiveKey="past"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="past" title="Past Trips">
            <TripMatchList
              className="tripMatchStyles"
              tripListKey="past"
            />
          </Tab>
        </TabsContainer>

      </>}
    />
  </RouteGuardSwitcher>
}

const PastTripRequestsPage: RouteProps = {
  component: Page,
  path: PastTripRequestsPageRoute,
  exact: true,
}

export default PastTripRequestsPage;
