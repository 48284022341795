import React, {useState} from "react";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import TripRequestCondensed from "../../app/trip-request/trip-request/trip-request-condensed";
import TripMatchList from "../../app/trip-matches/TripMatchList";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import {RouteProps} from "react-router-dom";
import useIncrement from "../../utl/hooks/useIncrement";
import TabsContainer from "../../ui-components/tabs/TabsContainer";
import Tab from "../../ui-components/tabs/Tab";
import FormField from "../../ui-components/form/form-field/FormField";
import {TripMatchesSearchContext} from "../../app/trip-matches/trip-matches-search/trip-matches-search-context";

export const DashboardPageRoute = "/dashboard";

const Page: React.FC = () => {
  const [refreshId, refreshTrips] = useIncrement()
  const [searchTerm, setSearchTerm] = useState<string>("");

  return <RouteGuardSwitcher routeGuard="portal">
    <PageLayout
      navType="app"
      heroType="large"
      cardContainerSlot={(<>
        <h2>New Trip Request</h2>
        <TripRequestCondensed onSuccess={refreshTrips}/>
      </>)}
    >
      <TripMatchesSearchContext.Provider value={searchTerm}>
        <div className="d-flex justify-content-between p-3 mb-0">
          <h2>Trips</h2>
          <FormField
            className="p-0"
            formControlProps={{
              placeholder: "search...",
              onChange: (e) => setSearchTerm(e.target.value),
            }}
            icon="search"
          />
        </div>
        <TabsContainer
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab eventKey="home" title="Matches">
            <TripMatchList tripListKey="matched" triggerRefresh={refreshId}/>
          </Tab>
          <Tab eventKey="profile" title="Requests">
            <TripMatchList tripListKey="pending" triggerRefresh={refreshId}/>
          </Tab>
          <Tab eventKey="contact" title="Confirmed">
            {/*<TripMatchList tripListKey="confirmed" triggerRefresh={refreshId}/>*/}
            <p className="mt-5">
              This functionality has yet to be developed. To accept or reject matches please contact your admin.
            </p>
          </Tab>
        </TabsContainer>
      </TripMatchesSearchContext.Provider>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const DashboardPage: RouteProps = {
  component: Page,
  path: DashboardPageRoute,
  exact: true,
};

export default DashboardPage;
