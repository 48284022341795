import React from "react";
import {Spinner} from "react-bootstrap";
import classNames from "classnames";

interface LoaderProps {
  show: boolean,
  className?: string,
}

const FullScreenLoader: React.FC<LoaderProps> = ({show, className, ...rest}) => {
  const baseClass = "full-screen-loader";
  const c = classNames([className, `${baseClass}__spinner`]);
  if (show)
    return <div className={baseClass}>
      <Spinner className={c} animation="grow" role="status" {...rest}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>;
  else
    return null;
};

export default FullScreenLoader;
