import React from "react";
import Form from "../../../ui-components/form/Form";
import {Col, Row} from "react-bootstrap";
import FormField from "../../../ui-components/form/form-field/FormField";
import useInputValue from "../../../ui-components/form/input-helpers/useInputValue";
import useAlerts from "../../../services/alerts/hooks/useAlerts";
import usePhoneInputMutator from "../../../ui-components/form/input-helpers/usePhoneInputMutator";
import PhoneInput from "../../../ui-components/form/inputs/phone/PhoneInput";
import TimezoneSelect from "../../../ui-components/form/inputs/timezone-select/TimezoneSelect";
import HearAboutSelect from "../../../ui-components/form/inputs/hear-about-select/HearAboutSelect";
import Button from "../../../ui-components/button/Button";

export interface SignUpFormData {
  first: string,
  last: string,
  email: string,
  password: string,
  companyName: string,
  phone: string,
  operatingTimezone: string,
  hearAboutUs: string,
}

interface SignUpFormProps {
  className?: string,
  onSubmit: (data: SignUpFormData) => void,
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  onSubmit,
  ...rest
}) => {
  const {popError} = useAlerts();
  const [email, setEmail] = useInputValue("");
  const [password, setPassword] = useInputValue("");
  const [passwordConfirm, setPasswordConfirm] = useInputValue("");
  const [firstNameValue, firstNameValueOnChange, setFirstNameValue] = useInputValue();
  const [lastNameValue, lastNameValueOnChange, setLastNameValue] = useInputValue();
  const [companyNameValue, companyNameValueOnChange, setCompanyNameValue] = useInputValue();
  const [phoneValue, phoneValueRaw, phoneValueOnChange, setPhoneValue] = usePhoneInputMutator();
  const [operatingTimezoneValue, operatingTimezoneValueOnChange, setOperatingTimezoneValue] = useInputValue();
  const [hearAboutUsValue, hearAboutUsValueOnChange, setHearAboutUsValue] = useInputValue();

  const handleSubmit = () => {
    if (password !== passwordConfirm) {
      popError("Passwords must match.");
    } else {
      if (!phoneValue) {
        popError("The phone number entered is not valid");
        return;
      }

      onSubmit({
        email,
        password,
        first: firstNameValue,
        last: lastNameValue,
        companyName: companyNameValue,
        phone: phoneValue.formatInternational(),
        operatingTimezone: operatingTimezoneValue,
        hearAboutUs: hearAboutUsValue,
      });
    }
  };

  return <Form
    onSubmit={handleSubmit}
    {...rest}
  >
    <Row>
      <Col>
        <h2 className="h4 mt-3">Account Information</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="First Name"
          formControlProps={{
            value: firstNameValue,
            onChange: firstNameValueOnChange,
            required: true,
          }}
        />
      </Col>
      <Col>
        <FormField
          label="Last Name"
          formControlProps={{
            value: lastNameValue,
            onChange: lastNameValueOnChange,
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Email"
          formControlProps={{
            type: "email",
            value: email,
            onChange: setEmail,
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Password"
          formControlProps={{
            type: "password",
            value: password,
            onChange: setPassword,
            required: true,
          }}
        />
      </Col>
      <Col>
        <FormField
          label="Confirm Password"
          formControlProps={{
            type: "password",
            value: passwordConfirm,
            onChange: setPasswordConfirm,
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <h2 className="h4 mt-4">Additional Contact Information</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Company Name"
          formControlProps={{
            value: companyNameValue,
            onChange: companyNameValueOnChange,
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Phone"
          formControlProps={{
            as: PhoneInput,
            value: phoneValueRaw,
            onChange: phoneValueOnChange,
            required: true,
          }}
        />
      </Col>
      <Col>
        <FormField
          label="Operating Timezone"
          formControlProps={{
            as: TimezoneSelect,
            onChange: operatingTimezoneValueOnChange,
            // defaultValue: operatingTimezoneValue,
            placeholder: "ex: (GMT-05:00) New York City",
            required: true,
            value: operatingTimezoneValue,
          }}
        />
      </Col>
    </Row>
    <Row className="mt-5">
      <Col>
        <FormField
          label="How did You hear about Us?"
          formControlProps={{
            as: HearAboutSelect,
            onChange: hearAboutUsValueOnChange,
            defaultValue: hearAboutUsValue,
            placeholder: "ex: LinkedIn",
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col className="d-flex justify-content-end">
        <Button className="mt-2" type="submit">Submit</Button>
      </Col>
    </Row>
  </Form>;
};

export default SignUpForm;
