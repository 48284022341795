import dayjs, {Dayjs} from "dayjs";
import TripEntity from "./trip-entity";
import {Record} from "react-bootstrap-icons";

interface Attachment {
  label: string,
  url: string,
}

class MatchEntity {
  public id: string;
  public matchedAt: Dayjs;
  private tripSpecificInfo: Record<string, any>;
  public hasBeenQuoted: boolean;
  public quote: string | undefined;
  public amenities: string;
  public aircraft: string;
  public attachments: Attachment[];

  constructor(dbData: Record<string, any>, trip: TripEntity) {
    // private
    const trips: Record<string, string>[] = dbData.trips || [];
    this.tripSpecificInfo = trips.find(t => trip.requestId === t.request_id) || {};

    // public
    this.id = dbData.id;
    this.aircraft = dbData.aircraft;
    this.amenities = dbData.amenities;
    this.matchedAt = dayjs(dbData.matched_at);
    this.quote = this.tripSpecificInfo.quote;
    this.attachments = this.tripSpecificInfo.attachments || [];
    this.hasBeenQuoted = Boolean(this.quote);
  }
}

export default MatchEntity;
