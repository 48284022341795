import React, {useEffect} from "react";
import {RouteProps, useHistory, useLocation} from "react-router-dom";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import Form from "../../ui-components/form/Form";
import FormField from "../../ui-components/form/form-field/FormField";
import {Row, Col} from "react-bootstrap";
import useInputValue from "../../ui-components/form/input-helpers/useInputValue";
import useAlerts from "../../services/alerts/hooks/useAlerts";
import useGlobalLoading from "../../services/loading/hooks/useGlobalLoading";
import {ForgotPasswordPageRoute} from "./ForgotPasswordPage";
import {changeForgottenPassword} from "../../services/auth/auth-service";
import {LoginPageRoute} from "./LoginPage";
import Button from "../../ui-components/button/Button";

/* This route must be configured in aws cognito login redirect */
export const ForgotPasswordSecondPageRoute = "/change-forgotten-password";

const Page: React.FC = () => {
  const [password, setPassword] = useInputValue("");
  const [verificationCode, setVerificationCode] = useInputValue("");
  const {popError, popSuccess} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading();
  let history = useHistory();
  const location = useLocation();
  // @ts-ignore
  const forgotPasswordEmail = location.state?.email;

  useEffect(() => {
    // If email is not in route state jump back to forgot password
    if (!forgotPasswordEmail) {
      history.push(ForgotPasswordPageRoute)
    }
  }, [forgotPasswordEmail])


  const handleSubmit = async () => {
    const loadingID = startLoading();
    try {
      await changeForgottenPassword(forgotPasswordEmail, verificationCode, password);
      history.push(LoginPageRoute);
      popSuccess("Password changed successfully. Please log in again.")
    } catch (e) {
      if (e instanceof Error)
        popError(e.message);
    }
    stopLoading(loadingID);
  };

  return <RouteGuardSwitcher routeGuard="landing">
    <PageLayout
      navType="landing"
      pageWidth="form"
    >
      <h1 className="mt-5 mb-4">Change Your Password.</h1>
      <Form onSubmit={handleSubmit}>
        <FormField
          label="Verification Code"
          formControlProps={{
            value: verificationCode,
            onChange: setVerificationCode,
            required: true,
          }}
        />
        <FormField
          label="New Password"
          formControlProps={{
            type: "password",
            value: password,
            onChange: setPassword,
            required: true,
          }}
        />
        <Row>
          <Col className="d-flex flex-row justify-content-between">
            <Button
              className="pl-0"
              variant="link"
              onClick={() => history.push(ForgotPasswordPageRoute)}
            >
              Request Another Code
            </Button>
            <Button className="mt-2" type="submit">Submit</Button>
          </Col>
        </Row>
      </Form>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const ForgotPasswordSecondPage: RouteProps = {
  component: Page,
  path: ForgotPasswordSecondPageRoute,
  exact: true,
};

export default ForgotPasswordSecondPage;
