const storageNotAvailableError = "Local storage not available";

export const storeValue = (key: string, value: string) => {
  if (typeof (Storage) !== "undefined") {
    localStorage.setItem(key, value);
  } else {
    throw new Error(storageNotAvailableError);
  }
};

export const getStoredValue = (key: string) => {
  if (typeof (Storage) !== "undefined") {
    localStorage.getItem(key);
    return localStorage.getItem(key);
  } else {
    throw new Error(storageNotAvailableError);
  }
};

export const clearValue = (key: string) => {
  if (typeof (Storage) !== "undefined") {
    localStorage.removeItem(key);
  } else {
    throw new Error(storageNotAvailableError);
  }
}
