import React from "react";
import TripEntity from "../../services/trips/trip-entity";
import {Button, ListGroup} from "react-bootstrap";
import ColoredBadge, {getColorFromTripRequestStatus} from "../../ui-components/colored-badge/ColoredBadge";
import classNames from "classnames";
import {ChevronRight} from "react-bootstrap-icons";

interface TripMatchListItemProps {
  trip: TripEntity,
  onClick: (trip: TripEntity) => void,
  className?: string,
}

const TripMatchListItem: React.FC<TripMatchListItemProps> = ({trip, onClick, className, ...rest}) => {
  const c = classNames([
    className,
    "trip-match-list__item",
  ]);
  return <ListGroup.Item
    key={trip.gsi1sk}
    className={c}
  >
    {trip.flights.map((flight, index) => {
      return <div
        key={trip.gsi1sk + index}
        className="trip-match-list__item__contents"
      >
        <div className="trip-field">
          {flight.fromAirportData.icao}-{flight.toAirportData.icao}
        </div>
        <div className="trip-field">
          {TripEntity.friendlyTripDate(
            flight.date,
          )}
        </div>
        <div className="trip-field trip-field--status">
          {trip.minAircraftSize}
        </div>
        <div className="trip-field trip-field--status">
          <ColoredBadge color={getColorFromTripRequestStatus(trip.status)}>
            {trip.status}
          </ColoredBadge>
        </div>
        <div className="trip-field trip-field--view">
          <Button
            variant="link"
            className="view-button d-flex align-items-center"
            onClick={() => onClick(trip)}
          >
            View Trip <ChevronRight />
          </Button>
        </div>
      </div>;
    })}
  </ListGroup.Item>;
};

export default TripMatchListItem;
