import React, {useState} from "react";

const useInputValue = (defaultValue?: string) => {
  const [value, setValue] = useState<string>(defaultValue || "");

  const onChange: React.ChangeEventHandler =
    // @ts-ignore
    (e) => setValue(e.target.value);

  return [
    value,
    onChange,
    setValue,
  ] as const;
};

export default useInputValue;
