import React from "react";
import {Dayjs} from "dayjs";
import {ListGroup} from "react-bootstrap";
import {Calendar, Clock} from "react-bootstrap-icons";
import classNames from "classnames";

interface InfoDatetimeProps {
  day: Dayjs,
  className?: string,
}

const InfoDatetime: React.FC<InfoDatetimeProps> = ({day, className, ...rest}) => {
  const baseClass = "jw-info-datetime";
  const c = classNames([
    baseClass,
    "list-group--no-lines",
    className,
  ]);

  if (!day.format) {
    console.warn("InfoDatetime called with invalid day prop.");
    return null;
  }

  return <ListGroup className={c} horizontal>
    <ListGroup.Item>
      <Calendar className={`${baseClass}--icon text-primary`}/>
      <span style={{paddingTop: "2px"}}>{day.format("M/D/YYYY")}</span>
    </ListGroup.Item>
    <ListGroup.Item>
      <Clock className={`${baseClass}--icon text-primary`}/>
      {day.utc().format("h:mmA")} local
    </ListGroup.Item>
  </ListGroup>;
};

export default InfoDatetime;
