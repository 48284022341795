import React, {
    useEffect,
    useRef,
    useState
  } from 'react';

  
  interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl ? : boolean;
    fromLat: number;
    fromLon: number;
    toLat: number;
    toLon: number;
  }
  
  interface IMarker {
    address: string;
    latitude: number;
    longitude: number;
  }
  
  type GoogleLatLng = google.maps.LatLng;
  type GoogleMap = google.maps.Map;
  type GoogleMarker = google.maps.Marker;
  type GooglePolyline = google.maps.Polyline;
  
  const Map: React.FC < IMap > = ({
    mapType,
    mapTypeControl = false,
    fromLat,
    fromLon,
    toLat,
    toLon
  }) => {

    const ref = useRef < HTMLDivElement > (null);
    const [map, setMap] = useState < GoogleMap > ();
    const [marker, setMarker] = useState < IMarker > ();
    const [departureMarker, setdepartureMarker] = useState < GoogleMarker > ();
    const [googleMarkers, setGoogleMarkers] = useState < GoogleMarker[] > ([]);
    const [listenerIdArray, setListenerIdArray] = useState < any[] > ([]);
    const [LastLineHook, setLastLineHook] = useState < GooglePolyline > ();
    const [distance, setDistance] = useState(0);

  
    const startMap = (): void => {
      const departureLocation = new google.maps.LatLng(fromLat, fromLon);
      const arrivalLocation = new google.maps.LatLng(toLat, toLon);
      const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(departureLocation, arrivalLocation);
      setDistance(Math.round(distanceInMeters * 0.000621371192))
  
      if (!map) {
        defaultMapStart(distance, fromLat, fromLon);
      } else {
        setdepartureMarker(addDepartureMarker(departureLocation));
        setdepartureMarker(addDepartureMarker(arrivalLocation))
      }
    };
    useEffect(startMap, [map]);
  
    const defaultMapStart = (distance: number, fromLat: number, fromLon: number): void => {
      const defaultAddress = new google.maps.LatLng(fromLat, fromLon);
      initMap(2, defaultAddress);
    };
  
    const initEventListener = (): void => {
      if (map) {
        google.maps.event.addListener(map, 'click', function (e) {
          coordinateToAddress(e.latLng);
        })
      }
    };
    useEffect(initEventListener, [map]);
  
    const coordinateToAddress = async (coordinate: GoogleLatLng) => {
      const geocoder = new google.maps.Geocoder();
      await geocoder.geocode({
        location: coordinate
      }, function (results, status) {
        if (status === 'OK') {
          setMarker({
            address: results[0].formatted_address,
            latitude: coordinate.lat(),
            longitude: coordinate.lng()
          })
        }
      });
    };

    useEffect(() => {
      // Set initial zoomLevel
      let zoomLevel = 3;
      // Set zoom based on distance, the closer
      // the distance the higher the zoom level
      if (distance >= 0 && map) {
        switch (true) {
          case distance > 7000:
            zoomLevel = 0;
            break;
          case distance > 6000:
            zoomLevel = 1;
            break;
          case distance > 5000:
            zoomLevel = 2;
            break;
          case distance > 700:
            zoomLevel = 3;
            break;
          default:
            zoomLevel = 4;
            break;
        }

        map.setZoom(zoomLevel)
      }
    }, [map, distance])
  
    useEffect(() => {
      if (marker) {
        addMarker(new google.maps.LatLng(marker.latitude, marker.longitude));
      }
    }, [marker]);
  
    const addMarker = (location: GoogleLatLng): void => {
      const marker: GoogleMarker = new google.maps.Marker({
        position: location,
        map: map,
        icon: getIconAttributes('#000000')
      });
  
      setGoogleMarkers(googleMarkers => [...googleMarkers, marker]);
  
      const listenerId = marker.addListener('click', () => {
        const departurePos = departureMarker?.getPosition();
        const markerPos = marker.getPosition();
        if (departurePos && markerPos) {
          const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(departurePos, markerPos);
          // TODO: determine if we are using this, if so convert to miles
          // *0.000621371192
          // setDistanceInKm(Math.round(distanceInMeters / 1000));
          // set distance in miles
          setDistance(Math.round(distanceInMeters * 0.000621371192))
          if (LastLineHook) {
            LastLineHook.setMap(null);
          }
  
          const line = new google.maps.Polyline({
            path: [{
                lat: departurePos.lat(),
                lng: departurePos.lng()
              },
              {
                lat: markerPos.lat(),
                lng: markerPos.lng()
              },
            ],
            icons: [{
              icon: {
                path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
              },
              offset: "100%"
            }],
            map: map,
          });
  
          setLastLineHook(line);
        }
      });
  
      setListenerIdArray(listenerIdArray => [...listenerIdArray, listenerId]);
    };
  
    useEffect(() => {
      listenerIdArray.forEach((listenerId) => {
        google.maps.event.removeListener(listenerId);
      });
  
      setListenerIdArray([]);
      setGoogleMarkers([]);
      googleMarkers.forEach((googleMarker) => {
        const markerPosition = googleMarker.getPosition();
        if (markerPosition) {
          addMarker(markerPosition);
        }
      });
    }, [LastLineHook]);
  
    const addDepartureMarker = (location: GoogleLatLng): GoogleMarker => {
      const departureMarkerConst: GoogleMarker = new google.maps.Marker({
        position: location,
        map: map,
      });
  
      departureMarkerConst.addListener('click', () => {
        map?.panTo(location);
        map?.setZoom(6);
      });
  
      return departureMarkerConst;
    };
  
    const getIconAttributes = (iconColor: string) => {
      return {
        path: 'M11.0639 15.3003L26.3642 2.47559e-05L41.6646 15.3003L26.3638 51.3639L11.0639 15.3003 M22,17.5a4.5,4.5 0 1,0 9,0a4.5,4.5 0 1,0 -9,0Z',
        fillColor: iconColor,
        fillOpacity: 0.8,
        strokeColor: 'pink',
        strokeWeight: 2,
        anchor: new google.maps.Point(30, 50)
      };
    };
  
    const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
      if (ref.current) {
        setMap(
          new google.maps.Map(ref.current, {
            zoom: zoomLevel,
            center: address,
            mapTypeControl: mapTypeControl,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: true,
            fullscreenControl: false,
            panControl: false,
            zoomControl: true,
            gestureHandling: 'cooperative',
            mapTypeId: mapType,
            draggableCursor: 'pointer',
          })
        );
      }
    };
  
    return ( 
      <div className = "jw-map-container">
        <div ref={ref} className ="jw-map-container__map">
        </div> 
      </div>
    );
  };
  
  export default Map;
