import {Link, matchPath, useLocation} from "react-router-dom";
import React from "react";
import classNames from "classnames";

export interface NavbarLinkProps {
  to: string,
  className?: string,
}

const NavbarLink: React.FC<NavbarLinkProps> = ({to, className, ...rest}) => {
  const location = useLocation();
  const c = classNames(
    "nav-link", // bootstrap class
    {"active": !!matchPath(location.pathname, {path: to, exact: true})},
    className,
  );

  return <Link
    className={c}
    to={to}
    {...rest}
  />;
};

export default NavbarLink;
