import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../../../ui-components/modal/components/ModalHeader";
import TripEntity from "../../../services/trips/trip-entity";
import InfoContainer from "../../../ui-components/info/InfoContainer";
import Info from "../../../ui-components/info/Info";
import Button from "../../../ui-components/button/Button";
import ButtonRow from "../../../ui-components/button-row/ButtonRow";
import useDismissModal from "../../../ui-components/modal/hooks/useDismissModal";
import {getTripRequestPageForId} from "../../../pages/portal/TripRequestPage";
import {useHistory} from "react-router-dom";

interface TripMatchModalProps {
  trip: TripEntity | null,
  onRequestAnotherTrip: () => void,
}

const TripRequestModal: React.FC<TripMatchModalProps> = ({
  trip,
  onRequestAnotherTrip,
}) => {
  const history = useHistory();
  const dismissModal = useDismissModal();
  const flight = trip?.flights[0];
  if (!trip) {
    return null;
  }

  const dismiss = (requestAnother: boolean) => {
    dismissModal();

    if (requestAnother) {
      onRequestAnotherTrip();
    }
  };

  return <div className="trip-requested-modal">
    <ModalHeader className="align-items-center">
      <Modal.Title as="h3">
        Trip Requested!
      </Modal.Title>
    </ModalHeader>

    <Modal.Body className="trip-requested-modal_body">
      <img
        className="flight-summary-graphic"
        src="/images/flight-summary.svg"
        alt="plane flying to destination"
      />
      <div className="trip-requested-modal_body__title">
        <span className="trip-requested-modal_body__title__icao">{flight?.fromAirportData.icao}</span>
        <img
          className="trip-requested-modal_body__title__arrow"
          src="/images/flight-arrow.svg"
          alt="right-arrow"
        />
        <span className="trip-requested-modal_body__title__icao">{flight?.toAirportData.icao}</span>
      </div>

      {flight && <>
        <p className="text-center text-sm">
          {TripEntity.friendlyAirportName(flight?.fromAirportData, false)} to {TripEntity.friendlyAirportName(flight?.toAirportData, false)}
        </p>
        <p className="text-center text-muted text-sm">
          Trip ID: #{trip.requestId}
        </p>
        <hr/>
        <InfoContainer>
          <Info label="Trip Date">
            {flight.departureInfo?.dateTime?.format("M/D/YYYY")}
          </Info>
          <Info label="Local Trip Time">
            {flight.departureInfo?.dateTime?.format("h:mmA")}
          </Info>
        </InfoContainer>
        <InfoContainer>
          <Info label="Aircraft Size">
            {trip?.minAircraftSize}
          </Info>
          <Info label="# of Passengers">
            {trip?.passengerCount}
          </Info>
          <Info label="Client Type">
            {trip?.clientType}
          </Info>
        </InfoContainer>
      </>
      }

      <p className="text-fine-print text-center mb-1">Once we find a match for this trip request we will notify you and
        begin the process of locating a quote.</p>
    </Modal.Body>

    <Modal.Footer>
      <ButtonRow alignment="center">
        <Button onClick={() => dismiss(true)}>Request Another Trip</Button>
        <Button variant="outline-dark" includeArrow onClick={() => {
          dismiss(false);
          history.push(getTripRequestPageForId(trip.dynamoFromAirport, trip.dynamoCompositeSort));
        }}>View Trips</Button>
      </ButtonRow>

    </Modal.Footer>
  </div>;
};

export default TripRequestModal;
