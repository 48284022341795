import {useContext} from "react";
import LoadingContext, {LoadingContextInterface} from "../loading-context";

// This hook is a pass through to the loading context to make this
// functionality more discoverable and easier to use.
const useGlobalLoading: () => LoadingContextInterface = () => {
  return useContext(LoadingContext)
}

export default useGlobalLoading;
