import React from "react";
import SignUpForm, {SignUpFormData} from "./SignUpForm";
import {EmailVerifyPageRoute} from "../../../pages/landing/EmailVerifyPage";
import useAlerts from "../../../services/alerts/hooks/useAlerts";
import useGlobalLoading from "../../../services/loading/hooks/useGlobalLoading";
import {useHistory} from "react-router-dom";
import useAuth from "../../../services/auth/hooks/useAuth";

interface SignUpProps {
  className?: string,
}

const SignUp: React.FC<SignUpProps> = ({
  className
}) => {
  const {popError} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading();
  let history = useHistory();
  const {signUp} = useAuth();

  const handleSubmit = async (data: SignUpFormData) => {
    const loadingID = startLoading();
    try {
      await signUp(data.email, data.password, data);
      history.push(EmailVerifyPageRoute, {
        email: data.email,
      });
    } catch (e) {
      if (e instanceof Error)
        popError(e.message);
    }
    stopLoading(loadingID);
  };

  return <div className={className}>
    <SignUpForm onSubmit={handleSubmit}/>
  </div>
};

export default SignUp;
