import React from "react";

// Alerts container positions it's children in the correct position on screen. It's children should be alerts.
const AlertsContainer: React.FC = ({children}) => {
  return <div className="alerts-container">
    {children}
  </div>
}

export default AlertsContainer;
