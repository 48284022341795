import React, {ChangeEvent, useEffect, useRef} from "react";
import {isValidPhoneNumber} from "libphonenumber-js";


export interface PhoneInputProps extends React.HTMLProps<HTMLInputElement> {}

const PhoneInput: React.FC<PhoneInputProps> = ({
  onChange,
  required,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      updateValidity(inputRef.current)
    }
  }, [inputRef]);

  const onChangeCheckValidity = (e: ChangeEvent<HTMLInputElement>) => {
    updateValidity(e.target);
    if (onChange)
      onChange(e);
  };

  const updateValidity = (input: HTMLInputElement) => {
    const value = input.value;
    if (!value) {
      if (!required) {
        input.setCustomValidity("");
      } else {
        input.setCustomValidity("Please fill out this field.");
      }
    } else if (isValidPhoneNumber(value, "US")) {
      input.setCustomValidity("");
    } else {
      input.setCustomValidity("Not a valid phone number.");
    }
  }

  return <input
    {...rest}
    type="tel"
    onChange={onChangeCheckValidity}
    ref={inputRef}
  />;
};

export default PhoneInput;
