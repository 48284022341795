import React from "react";
import classNames from "classnames";

export type CardContainerTypes = "white" | "gray";

interface CardContainerProps {
  className?: string,
  type: CardContainerTypes,
  centerHeaders?: boolean,
  smPadding?: boolean,
}

const CardContainer: React.FC<CardContainerProps> = ({
  className,
  type,
  centerHeaders,
  children,
  smPadding,
  ...rest
}) => {
  const baseClass = "jw-card-container";
  const c = classNames(
    baseClass,
    `${baseClass}--${type}`,
    {[`${baseClass}--center-h`]: centerHeaders},
    {[`${baseClass}--sm-padding`]: smPadding},
    className,
  );
  return <div className={c} {...rest}>
    <div className="jw-card-container__contents">
      {children}
    </div>
  </div>;
};

export default CardContainer;
