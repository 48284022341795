import React from "react";
import classNames from "classnames";
import {ListGroup} from "react-bootstrap";

interface ListPillProps {
  className?: string,
}

const ListPill: React.FC<ListPillProps> = ({className, ...rest}) => {
  const c = classNames([
    "jw-list-pill",
    className,
  ]);
  return <ListGroup className={c} horizontal {...rest} />;
};

export default ListPill;
