import React, {ReactNode} from "react";
import Info from "../../../ui-components/info/Info";
import {CheckCircle} from "react-bootstrap-icons";
import {AiOutlineCloudServer} from "react-icons/ai";

type DetailsWithIconIcons = "server" | "check";

interface DetailsWithIconProps {
  className?: string,
  title: string,
  icon: DetailsWithIconIcons,
  children: ReactNode,
}

const DetailsWithIcon: React.FC<DetailsWithIconProps> = ({title, icon, children, ...rest}) => {
  return <div className="jw-details-with-icon" {...rest}>
    {icon === "server" && <AiOutlineCloudServer className="jw-details-with-icon__icon"/>}
    {icon === "check" && <CheckCircle className="jw-details-with-icon__icon"/>}
    <Info label={title}>
      {children}
    </Info>
  </div>;
};

export default DetailsWithIcon;
