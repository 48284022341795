import React, {InputHTMLAttributes, useState} from "react";
import classNames from "classnames";
import {Form, FormControlProps, FormGroupProps as BoostrapFormGroupProps} from "react-bootstrap";
import FormControlFeedback, {FormControlFeedbackProps} from "./form-control-feedback/form-control-feedback";

type JetWireFormControlProps = InputHTMLAttributes<HTMLInputElement> & FormControlProps & { [key: string]: any };

interface FormFieldProps extends BoostrapFormGroupProps {
  label?: string,
  helpText?: string,
  className?: string,
  compressed?: boolean,
  formControlProps?: JetWireFormControlProps,
  formControlFeedbackProps?: FormControlFeedbackProps,
  icon?: "takeoff" | "landing" | "search" | "calendar" | "clock",
}

/*
  FormGroup
  A helper component that makes it easier to use React Bootstrap's Form Elements by introducing a simpler api. Hopefully
  this also makes forms appear more consistent throughout the application.
 */
const FormField: React.FC<FormFieldProps> = ({
  children,
  className,
  label,
  helpText,
  compressed,
  formControlProps,
  formControlFeedbackProps,
  icon,
  ...formGroupProps
}) => {
  const baseClass = "jw-form-group";
  const cn = classNames([
    baseClass,
    {[`${baseClass}--compressed`]: compressed},
    {[`${baseClass}--has-icon`]: Boolean(icon)},
    {[`${baseClass}--${icon}`]: Boolean(icon)},
    "position-relative",
    className,
  ]);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [defaultInvalidMessage, setDefaultInvalidMessage] = useState<string>("");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultInvalidMessage(e.target.validationMessage);

    if (formControlProps?.onChange) {
      formControlProps.onChange(e);
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(true);

    if (formControlProps?.onFocus) {
      formControlProps.onFocus(e);
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(false);
    if (formControlProps?.onFocus) {
      formControlProps.onFocus(e);
    }
  };

  return <Form.Group className={cn} {...formGroupProps}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className={baseClass + "__input-container"}>
      <Form.Control
        {...formControlProps}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </div>

    {helpText && <Form.Text className="text-muted">
      {helpText}
    </Form.Text>}
    <FormControlFeedback
      inputHasFocus={hasFocus}
      invalidMessage={defaultInvalidMessage}
      tooltip={compressed}
      {...formControlFeedbackProps}
    />
    {children}
  </Form.Group>;
};

export default FormField;
