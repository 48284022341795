import {useContext} from "react";
import AlertsContext, {AlertContextInterface} from "../alerts-context";

// This hook is a pass through to the alerts context to make this
// functionality more discoverable and easier to use.
const useAlerts: () => AlertContextInterface = () => {
  return useContext(AlertsContext)
}

export default useAlerts;
