import React from "react";
import AlertBase, {AlertRawTransitionProps} from "./alert-base";

export type AlertType = "success" | "notification" | "error"

interface AlertProps {
  type: AlertType,
  className?: string,
}

const Alert: React.FC<AlertRawTransitionProps & AlertProps> = ({type, ...rest}) => {
  let alertVariant = "";

  // map alert types to bootstrap's variations
  if (type === "success") {
    alertVariant = "success";
  }
  if (type === "notification") {
    alertVariant = "warning";
  }
  if (type === "error") {
    alertVariant = "danger";
  }

  return <AlertBase variant={alertVariant} {...rest} />;
};

export default Alert;
