import React, {useEffect, useState} from "react";
import TripEntity from "../../services/trips/trip-entity";
import ColoredBadge, {getColorFromTripRequestStatus} from "../../ui-components/colored-badge/ColoredBadge";
import InfoDatetime from "../../ui-components/info-datetime/InfoDatetime";
import Info from "../../ui-components/info/Info";
import ListPillItem from "../../ui-components/list-pill/ListPIllItem";
import ListPill from "../../ui-components/list-pill/ListPill";
import Map from "../../ui-components/map/Map";
import {Col, Row} from "react-bootstrap";

interface TripDetailsProps {
  trip: TripEntity,
  className?: string,
}

const loadMapApi = () => {
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAXJnvKbCm7cgFNAU8SiByNnymjDLCQeec&libraries=geometry,places&&v=quarterly`;
  const scripts = document.getElementsByTagName("script");
  // Go through existing script tags, and return google maps api tag when found.
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(mapsURL) === 0) {
      return scripts[i];
    }
  }

  const googleMapScript = document.createElement("script");
  googleMapScript.src = mapsURL;
  googleMapScript.async = true;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};
loadMapApi();

const TripDetails: React.FC<TripDetailsProps> = ({trip, ...rest}) => {
  // loadMapApi();
  const [scriptLoaded, setScriptLoaded] = useState(false);


  useEffect(() => {
    if (window.google === undefined || window.google == null) {
      setScriptLoaded(false);
    } else {
      setScriptLoaded(true);
    }
  }, [window.google]);

  const flight = trip?.flights[0];
  const departureTime = trip.getFlight()?.departureInfo.dateTime;

  return <Row {...rest}>
    <Col className="detailsAndMap">
      <ColoredBadge className="mb-3" color={getColorFromTripRequestStatus(trip.status)}>
        {trip.status}
      </ColoredBadge>
      {flight && <>
        <h1 className="h2 mb-4">{flight?.fromAirportData.icao} - {flight?.toAirportData.icao}</h1>
        <p className="text-sm">
          {TripEntity.friendlyAirportName(flight?.fromAirportData, false)}
          <br/>to {TripEntity.friendlyAirportName(flight?.toAirportData, false)}
        </p>
        <p className="text-muted text-sm">
          Trip ID: #{trip.requestId}
        </p>
        {departureTime && <InfoDatetime day={departureTime}/>}
      </>}
      <ListPill>
        <ListPillItem>
          <Info label="Aircraft Size">
            {trip?.minAircraftSize}
          </Info>
        </ListPillItem>
        <ListPillItem>
          <Info label="# of Passengers">
            {trip?.passengerCount} pax
          </Info>
        </ListPillItem>
        <ListPillItem>
          <Info label="Client Type">
            {trip?.clientType}
          </Info>
        </ListPillItem>
      </ListPill>
    </Col>
    <Col className="detailsAndMap d-none d-md-block">
      {scriptLoaded && (
        <Map
          fromLat={trip.flights[0].fromAirportData.latitude_deg ? trip.flights[0].fromAirportData.latitude_deg : 0}
          fromLon={trip.flights[0].fromAirportData.longitude_deg ? trip.flights[0].fromAirportData.longitude_deg : 0}
          toLat={trip.flights[0].toAirportData.latitude_deg ? trip.flights[0].toAirportData.latitude_deg : 0}
          toLon={trip.flights[0].toAirportData.longitude_deg ? trip.flights[0].toAirportData.longitude_deg : 0}
          mapType={google.maps.MapTypeId.ROADMAP}
          mapTypeControl={true}
        />
      )}
    </Col>
  </Row>;
};

export default TripDetails;
