import React from "react";
import {environmentVariable} from "../../utl/environment";
import {RouteProps} from "react-router-dom";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";

/* This route must be configured in aws cognito logout redirect */
export const HomePageRoute = "/";

function Page() {
  return (
    <RouteGuardSwitcher routeGuard="landing">
      <PageLayout navType="landing">
        <div>
          <h1>Welcome</h1>
          <p>Environment is {environmentVariable("NODE_ENV")}</p>
          <p>Backend Url is {environmentVariable("REACT_APP_BACKEND_URL")}</p>
        </div>
      </PageLayout>
    </RouteGuardSwitcher>
  );
}

const HomePage: RouteProps = {
  component: Page,
  path: HomePageRoute,
  exact: true,
}

export default HomePage;
