import React from "react";
import {RouteProps, useHistory, useLocation} from "react-router-dom";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import Form from "../../ui-components/form/Form";
import FormField from "../../ui-components/form/form-field/FormField";
import {Row, Col} from "react-bootstrap";
import useInputValue from "../../ui-components/form/input-helpers/useInputValue";
import useAlerts from "../../services/alerts/hooks/useAlerts";
import useGlobalLoading from "../../services/loading/hooks/useGlobalLoading";
import useAuth from "../../services/auth/hooks/useAuth";
import {ForgotPasswordPageRoute} from "./ForgotPasswordPage";
import {ROUTE_AFTER_AUTHENTICATION} from "../../config";
import {EmailVerifyPageRoute} from "./EmailVerifyPage";
import CardContainer from "../../ui-components/card-container/CardContainer";
import Button from "../../ui-components/button/Button";

export const LoginPageRoute = "/login";

const Page: React.FC = () => {
  const location = useLocation();
  // @ts-ignore
  const userEmail = location.state?.email || "";
  const [email, setEmail] = useInputValue(userEmail);
  const [password, setPassword] = useInputValue("");
  const {popError} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading();
  const history = useHistory();
  const {login} = useAuth();

  const handleForgotPassword = () => {
    history.push(ForgotPasswordPageRoute);
  };

  const handleSubmit = async () => {
    const loadingID = startLoading();
    try {
      await login(email, password);
      history.push(ROUTE_AFTER_AUTHENTICATION);
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        if (e.name === "UserNotConfirmedException") {
          history.push(EmailVerifyPageRoute, {
            email,
          });
        }
        else {
          popError(e.message);
        }
      }
    }
    stopLoading(loadingID);
  };

  return <RouteGuardSwitcher routeGuard="landing">
    <PageLayout
      navType="landing"
      pageWidth="form"
    >
      <CardContainer type="white">
        <h1>Log in to your account.</h1>
        <Form onSubmit={handleSubmit}>
          <FormField
            label="Email"
            formControlProps={{
              type: "email",
              value: email,
              onChange: setEmail,
              required: true,
            }}
          />
          <FormField
            label="Password"
            formControlProps={{
              type: "password",
              value: password,
              onChange: setPassword,
              required: true,
            }}
          />
          <Row>
            <Col className="d-flex flex-row justify-content-between align-items-center">
              <Button
                className="pl-0 pb-0"
                variant="link"
                onClick={handleForgotPassword}
              >
                Forgot Password
              </Button>
              <Button className="mt-2" type="submit" variant="primary">Submit</Button>
            </Col>
          </Row>
        </Form>
      </CardContainer>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const ProcessLoginPage: RouteProps = {
  component: Page,
  path: LoginPageRoute,
  exact: true,
};

export default ProcessLoginPage;
