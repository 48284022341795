import React from "react";
import Select, {SelectProps} from "../select/Select";
import useTimezoneOptions from "./useTimezoneOptions";

interface TimezoneSelectProps extends Omit<SelectProps, "options"|"defaultValue"> {
  defaultValue?: string,
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  value,
  defaultValue,
  ...rest
}) => {
  const {options, isLoading} = useTimezoneOptions();
  const defaultV = value || defaultValue;

  /*
  Default value should be value or fallback to the defaultValue prop. If there's no options that match the value
  prop create an option with the value to preserve it. Falling back to options creation is for options that
  aren't known when the component is initialized.
  */
  const dv = (defaultV ? (
    {value: defaultV, label: options.find(o => o.value === defaultV)?.label || defaultV}
  ) : undefined);

  return <Select
    options={options}
    isLoading={isLoading}
    defaultValue={dv}
    {...rest}
  />;
};

export default TimezoneSelect;
