import React, {useEffect, useState} from "react";
import TripEntity from "../../../services/trips/trip-entity";
import usePresentModal from "../../../ui-components/modal/hooks/usePresentModal";
import TripRequestModal from "./TripRequestModal";

const useTripRequestModal = (onRequestAnotherTrip: () => void) => {
  const [trip, setTrip] = useState<TripEntity | null>(null);
  const present = usePresentModal(<TripRequestModal trip={trip} onRequestAnotherTrip={onRequestAnotherTrip}/>);

  useEffect(() => {
    if (trip) {
      present();
    }
  }, [trip]);

  return setTrip;
}

export default useTripRequestModal;
