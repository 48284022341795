import React from "react";
import {FormControl} from "react-bootstrap";
import classNames from "classnames";

export interface FormControlFeedbackProps {
  invalidMessage?: string,
  validMessage?: string,
  tooltip?: boolean,
  inputHasFocus?: boolean,
}

const FormControlFeedback: React.FC<FormControlFeedbackProps> = ({
  invalidMessage,
  validMessage,
  tooltip,
  inputHasFocus,
  ...rest
}) => {
  const baseClass = 'jw-form-feedback';
  const c = classNames([
    baseClass,
    {[`${baseClass}--tooltip`]: tooltip},
    {[`${baseClass}--focused`]: inputHasFocus},
  ]);

  return <>
    {Boolean(invalidMessage) && <FormControl.Feedback className={c} type="invalid" tooltip={tooltip} {...rest}>{invalidMessage}</FormControl.Feedback>}
    {Boolean(validMessage) && <FormControl.Feedback className={c} type="valid" tooltip={tooltip}  {...rest}>{validMessage}</FormControl.Feedback>}
  </>;
};

export default FormControlFeedback;
