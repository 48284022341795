import React from "react";
import {Container, Nav, Navbar as BootstrapNavbar} from "react-bootstrap";
import {DashboardPageRoute} from "../../pages/portal/DashboardPage";
import {getHost} from "../../utl/environment";

interface NavbarProps {
  title: string,
  children?: React.ReactNode,
  fontSlot?: React.ReactNode,
  collapsable?: boolean,
}

const Navbar: React.FC<NavbarProps> = ({
  title,
  children,
  fontSlot,
  collapsable = true,
}) => {
  const includeMainSlot = Boolean(children);
  return <BootstrapNavbar className="jw-navbar" expand="lg">
    <Container>
      <BootstrapNavbar.Brand href={DashboardPageRoute}>
        <img
          src={`/images/logo.png`}
          height="57"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </BootstrapNavbar.Brand>
      <NavbarLinksWrapper collapsable={collapsable}>
        <Nav className="me-auto">
          {fontSlot}
        </Nav>
        {includeMainSlot && (
          <Nav className="align-items-center justify-content-end" navbarScroll>
            {children}
          </Nav>
        )}
      </NavbarLinksWrapper>
    </Container>
  </BootstrapNavbar>;
};

interface NavbarLinksWrapperProps {
  collapsable: boolean,
}

const NavbarLinksWrapper: React.FC<NavbarLinksWrapperProps> = ({collapsable, children}) => {
  if (collapsable) {
    return <>
      <BootstrapNavbar.Toggle aria-controls="navbarScroll"/>
      <BootstrapNavbar.Collapse id="navbarScroll">{children}</BootstrapNavbar.Collapse>
    </>
  }
  return <>{children}</>;
}

export default Navbar;
