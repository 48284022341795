import React from "react";
import content from "../../../content/content";
import {DashboardPageRoute} from "../../../pages/portal/DashboardPage";
import useAuth from "../../../services/auth/hooks/useAuth";
import {PastTripRequestsPageRoute} from "../../../pages/portal/PastTripRequestsPage";
import {AccountPageRoute} from "../../../pages/portal/AccountPage";
import NavbarLink from "../../nav/NavbarLink";
import Navbar from "../../nav/Navbar";
import Button from "../../button/Button";

const AppNavbar: React.FC = () => {
  const {goToLogout} = useAuth();

  return <Navbar
    title={content.navbarTitle}
  >
    <NavbarLink to={DashboardPageRoute}>
      {content.routeNames.dashboard}
    </NavbarLink>
    <NavbarLink to={PastTripRequestsPageRoute}>
      {content.routeNames.pastRequests}
    </NavbarLink>
    <NavbarLink to={AccountPageRoute}>
      {content.routeNames.account}
    </NavbarLink>
    <Button variant="dark" onClick={goToLogout} includeArrow={false} size={"sm"}>
      {content.logoutButton}
    </Button>
  </Navbar>;
};

export default AppNavbar;
