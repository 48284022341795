import {fetchHelper} from "../network/fetch-helper";
import {getHost} from "../environment";

const timezoneFileURL = `${getHost()}/timezone-data/timezones.json`;

type timezoneJSON = {
  [tzCode: string]: string,
}

const getTimezones = async () => {
  console.log("getTimezones");
  try {
    const data: timezoneJSON = await fetchHelper({
      url: timezoneFileURL,
      method: "GET",
      includeAuth: false,
    });
    return Object.keys(data).map(key => {
      return {
        value: key,
        label: data[key],
      };
    });
  } catch (e) {
    console.log("e", e);
    throw new Error(`Can not find timezone file and therefore can not populate options.`);
  }
};

export default getTimezones;
