import React from "react";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import {RouteProps} from "react-router-dom";
import EditableAccountInfo from "../../app/account/edit-account-info/EditableAccountInfo";
import EditProfileInfo from "../../app/account/edit-profile-info/EditProfileInfo";
import CardContainer from "../../ui-components/card-container/CardContainer";

export const AccountPageRoute = "/account";

const Page: React.FC = () => {
  return <RouteGuardSwitcher routeGuard="portal">
    <PageLayout
      navType="app"
      pageWidth="form"
    >
      <CardContainer type={"white"}>
        <h1 className="mb-5">Profile</h1>
        <EditProfileInfo />
      </CardContainer>
      <CardContainer type={"white"}>
        <h2>Account</h2>
        <EditableAccountInfo />
      </CardContainer>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const DashboardPage: RouteProps = {
  component: Page,
  path: AccountPageRoute,
  exact: true,
};

export default DashboardPage;
