import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import TripEntity from "../trip-entity";
import {TripRequestPageParams} from "../../../pages/portal/TripRequestPage";
import {getTrip} from "../trips-service";
import useAlerts from "../../alerts/hooks/useAlerts";
import useGlobalLoading from "../../loading/hooks/useGlobalLoading";

const useTripFromLocation = () => {
  const {startLoading, stopLoading} = useGlobalLoading();
  const {popError} = useAlerts();
  const {fromAirport, compositeSort} = useParams<TripRequestPageParams>();
  const [trip, setTrip] = useState<TripEntity | undefined>();

  useEffect(() => {
    (async () => {
      const loaderId = startLoading();
      try {
        const trip = await getTrip(fromAirport, decodeURI(compositeSort));
        setTrip(trip);
      } catch (e) {
        if (e instanceof Error)
          popError(e.message);
      }
      stopLoading(loaderId);
    })();
  }, [fromAirport, compositeSort]);

  return {
    trip,
  };
};

export default useTripFromLocation;
