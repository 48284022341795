import React from "react";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import {RouteProps, useHistory} from "react-router-dom";
import ButtonRow from "../../ui-components/button-row/ButtonRow";
import useTripFromLocation from "../../services/trips/hooks/useTripFromLocation";
import Button from "../../ui-components/button/Button";
import TripDetails from "../../app/trip-details/TripDetails";
import {Col, Row} from "react-bootstrap";
import TripMatchDetails from "../../app/trip-match-details/TripMatchDetails";


export type TripRequestPageParams = {
  fromAirport: string;
  compositeSort: string;
};

export const TripRequestPageRoute = "/trip/:fromAirport/:compositeSort";
export const getTripRequestPageForId = (fromAirport: string, compositeSort: string) => (
  TripRequestPageRoute.replace(":fromAirport", fromAirport).replace(":compositeSort", encodeURIComponent(compositeSort))
);

const Page: React.FC = () => {
  const {trip} = useTripFromLocation();
  const history = useHistory();

  return <RouteGuardSwitcher routeGuard="portal">
    <PageLayout navType="app">
      <ButtonRow alignment="left">
        <Button isBackButton onClick={() => history.goBack()}>Back</Button>
      </ButtonRow>
      <hr/>
      {trip && <>
        <TripDetails
          trip={trip}
        />
        <Row>
          <Col>
            <h1 className="h2">Your Match</h1>
            <TripMatchDetails trip={trip}/>
          </Col>
        </Row>
      </>}
    </PageLayout>
  </RouteGuardSwitcher>;
};

const TripRequestPage: RouteProps = {
  component: Page,
  path: TripRequestPageRoute,
  exact: true,
};

export default TripRequestPage;
