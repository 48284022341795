import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import useAuth from "./useAuth";

/* This hook redirects the user to a given url once they're logged in and stores their token for later */
const useRedirectOnAuth = (redirectURL: string, redirectType: "AUTHENTICATED" | "UNAUTHENTICATED") => {
  const {isAuthenticated} = useAuth();
  let history = useHistory();

  useEffect(() => {
    if (
      (redirectType === "AUTHENTICATED" && isAuthenticated) ||
      (redirectType === "UNAUTHENTICATED" && !isAuthenticated)
    ) {
      history.push(redirectURL);
    }
  }, [isAuthenticated, redirectType, history, redirectURL]);
};

export default useRedirectOnAuth;
