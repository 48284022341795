import {fetchHelper} from "../network/fetch-helper";
import {getHost} from "../environment";
import {nullish} from "../types/type-utilities";
import {AirportOptions} from "../../ui-components/form/inputs/airport-select/useAirportOptions";
import {OptionType} from "../../ui-components/form/inputs/select/Select";

type airportJSON = {
  [ICAO: string]: {
    ICAO: string,
    IATA: string,
    name: string,
    country: string,
    state: string,
  }
}

const defaultAirportOptionsFile = `${getHost()}/airport-data/airports-default.json`;

type GetAirportOptions = (firstLetter: string | nullish, secondLetter: string | nullish) => Promise<AirportOptions>;

const getAirportOptions: GetAirportOptions = async (firstLetter, secondLetter) => {
  const useDefaultData = !firstLetter || !secondLetter;

  // Use default airports first two characters haven't been entered
  let url = "";
  if (useDefaultData)
    url = defaultAirportOptionsFile;
  else {
    url = `${getHost()}/airport-data/airports-${firstLetter.toLowerCase()}${secondLetter.toLowerCase()}.json`;
  }

  try {
    const data = await fetchHelper({
      url,
      method: "GET",
      includeAuth: false,
    });
    return Object.keys(data).map(key => {
      const option = data[key];
      return {
        value: option.ICAO,
        label: `${option.ICAO}|${option.IATA}|${option.name}|${option.country}|${option.state}|${option.municipality}`,
      };
    });
  } catch (e) {
    if (useDefaultData) {
      throw new Error(`Can not find airport options for ${firstLetter}${secondLetter}.`);
    } else {
      console.warn(`Can not find airport options for ${firstLetter}${secondLetter}. Defaulting to default options.`);
    }

    return getAirportOptions(null, null); // default to original data
  }
};

export const formatAirportOptionLabel = (option: OptionType) => {
  const [icoa, iata, name, country, state, municipality] = option.label.split("|");

  if (icoa && name && country && state && municipality) {
    return `${icoa} - ${name} · ${municipality}, ${state} ${country}`
  } else if (icoa && name && country && state) {
    return `${icoa} - ${name} · ${state} ${country}`;
  } else if (icoa && name && country) {
    return `${icoa} - ${name} · ${country}`;
  } else if (name || country) {
    return `${icoa} - ${name || country}`;
  } else {
    return icoa;
  }
};

export default getAirportOptions;
