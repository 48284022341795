import React, {ReactNode} from "react";
import LandingNavbar from "./nav-bars/LandingNavbar";
import {Container} from "react-bootstrap";
import AppNavbar from "./nav-bars/AppNavbar";
import classNames from "classnames";
import CardContainer from "../card-container/CardContainer";

type navType = "landing" | "app";
type PageLayoutPageWidth = "default" | "form";
type HeroType = "default" | "large";

interface LandingPageLayoutProps {
  navType: navType,
  pageWidth?: PageLayoutPageWidth,
  className?: string,
  heroType?: HeroType,
  largeHero?: boolean,
  cardContainerSlot?: ReactNode,
}

const navbarSwitch = (navType: navType) => ({
  "landing": <LandingNavbar/>,
  "app": <AppNavbar/>,
})[navType];

const heroURLSwitch = (heroType: HeroType| undefined) => {
  if (!heroType) {
    console.warn("heroURLSwitch is called with unknown value");
    return "";
  }

  return ({
    "default": `/images/hero-small.jpg`,
    "large": `/images/hero-large.jpg`,
  })[heroType];
}

const PageLayout: React.FC<LandingPageLayoutProps> = ({
  children,
  className,
  navType,
  pageWidth,
  heroType,
  cardContainerSlot,
}) => {
  const includeHero = Boolean(heroType);
  const c = classNames([
    "jw-page-layout",
    {"jw-page-layout--form": pageWidth === "form"},
    {"jw-page-layout--hero": includeHero},
    {"jw-page-layout--hero-large": includeHero && heroType === "large"},
    className,
  ]);
  return <div className={c}>
    {navbarSwitch(navType)}
    {includeHero && (<img
      src={heroURLSwitch(heroType)}
      className="jw-page-layout__hero"
      alt="background"
    />)}
    <Container className="jw-page-layout__container mt-5 mb-5">
      {cardContainerSlot && (
        <CardContainer className="jw-page-layout__card-slot" type="white" centerHeaders={true}>
          {cardContainerSlot}
        </CardContainer>
      )}
      {children}
    </Container>
    <img className="jw-page-layout__bg-logo" src="/images/large-bg-logo.svg" alt="background logo" />
  </div>;
};

export default PageLayout;
