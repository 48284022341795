import React from "react";
import useRedirectOnAuth from "../../../services/auth/hooks/useRedirectOnAuth";
import {UNAUTENTICATED_LANDING_PAGE} from "../../app-global/App";

const PortalRouteGuard: React.FC = ({children}) => {
  useRedirectOnAuth(UNAUTENTICATED_LANDING_PAGE, "UNAUTHENTICATED");

  return <>
    {children}
  </>;
};

export default PortalRouteGuard;
