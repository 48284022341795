import {fetchHelper} from "../network/fetch-helper";
import {getHost} from "../environment";

const hearAboutFileURL = `${getHost()}/hear-about-data/hear-about.json`;

type hearAboutJSON = {
  [hearAbout: string]: string,
}

const getHearAbout = async () => {
  console.log("getHearAbout");
  try {
    const data: hearAboutJSON = await fetchHelper({
      url: hearAboutFileURL,
      method: "GET",
      includeAuth: false,
    });
    return Object.keys(data).map(key => {
      return {
        value: key,
        label: data[key],
      };
    });
  } catch (e) {
    console.log("e", e);
    throw new Error(`Can not find hear about file and therefore can not populate options.`);
  }
};

export default getHearAbout;
