/*
  useTimezoneOptions will return options that work with react-select. Here's information about
  react-select: https://react-select.com/home
*/
import getTimezones from "../../../../utl/timezones/get-timezones";
import {useEffect, useState} from "react";
import useAlerts from "../../../../services/alerts/hooks/useAlerts";

export type TimezoneOptions = { label: string, value: string }[];

const useTimezoneOptions = () => {
  const [options, setOptions] = useState<TimezoneOptions>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {popError} = useAlerts();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const timezoneOptions = await getTimezones();
        setOptions(timezoneOptions);
      } catch (e) {
        if (e instanceof Error)
          popError(e.message);
      }
      setIsLoading(false);
    })()
  }, [popError]);

  return {
    options,
    isLoading,
  };
}

export default useTimezoneOptions;
