import React from "react";
import classNames from "classnames";

interface ListProps {
  className?: string,
}

const List: React.FC<ListProps> = ({className, ...rest}) => {
  const c = classNames("jw-list", className);

  return <div className={c} {...rest} />;
};

export default List;
