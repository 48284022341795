import React, {useState} from "react";
import {uniqueId, last} from "lodash";
import Modal, {ModalBaseProps} from "../components/ModalBase";
import {ModalIdContext} from "./modal-id-context";

interface ModalToBePresented extends ModalBaseProps {
  onDismiss?: () => void,
}

export interface DismissOptions {
  dismissWithoutAnimation?: boolean;
}

export interface ModalContextInterface {
  presentModal: (modal: ModalToBePresented) => string,
  dismiss: (id: string, options?: DismissOptions) => void,
  setModalLoading: (id: string, isLoading: boolean) => void,
}

export const ModalStackContext = React.createContext<ModalContextInterface>({
  presentModal: () => "unknown-id",
  dismiss: () => {},
  setModalLoading: () => {},
});

interface modalState extends ModalToBePresented {
  id: string,
  dismissing: boolean,
  isLoading: boolean,
}

export const ModalContextProvider: React.FC = ({children}) => {
  const [modals, setModals] = useState<modalState[]>([]);
  const displayModalId = last(modals.filter(m => !m.dismissing))?.id;

  const presentModal = (modal: ModalToBePresented) => {
    const modalId = uniqueId("modal-");

    setModals(currentState => {
      return [
        ...currentState,
        {
          id: modalId,
          dismissing: false,
          isLoading: false,
          ...modal,
        },
      ];
    });

    return modalId;
  };

  const dismiss = (modalId: string, options: DismissOptions = {}) => {
    const {dismissWithoutAnimation} = options;

    setModals(currentState => {
      return currentState.map(m => {
        if (m.id !== modalId)
          return m;
        else
          return {
            ...m,
            dismissing: true,
            animation: !dismissWithoutAnimation,
          };
      });
    });
  };

  const setModalLoading = (modalId: string, isLoading: boolean) => {
    setModals(currentState => {
      return currentState.map(m => {
        if (m.id !== modalId)
          return m;
        else
          return {
            ...m,
            isLoading,
          };
      });
    });
  };

  const modalDismissed = (modalId: string) => {
    // Since the modal has been dismissed we can remove it from the state
    setModals(currentState => {
      return currentState.filter(m => m.id !== modalId);
    });
  }

  return <ModalStackContext.Provider value={{
    presentModal,
    dismiss,
    setModalLoading,
  }}>
    {children}
    {modals.map(({
      id,
      dismissing,
      isLoading,
      children,
      onDismiss,
      ...rest
    }) => {
      const isShown = displayModalId === id;
      return <Modal
        key={id}
        show={isShown}
        isLoading={isLoading}
        onHide={() => {
          if (isShown) {
            dismiss(id);
            if (onDismiss) {
              onDismiss();
            }
          }
        }}
        onExited={() => {
          if (dismissing) {
            modalDismissed(id);
          }
        }}
        {...rest}
      >
        <ModalIdContext.Provider value={id}>
          {children}
        </ModalIdContext.Provider>
      </Modal>;
    })}
  </ModalStackContext.Provider>;
};

export default ModalStackContext;



