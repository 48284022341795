import React from "react";
import classNames from "classnames";
import {Modal as BootstrapModal, ModalProps as BootstrapModalProps} from "react-bootstrap";
import ModalLoader from "../../loaders/modal-loader/ModalLoader";

export interface ModalBaseProps extends BootstrapModalProps{
  children: React.ReactNode,
  className?: string,
  preventHide?: boolean,
  isLoading?: boolean,
}

const Modal: React.FC<ModalBaseProps> = ({
  className,
  onHide,
  preventHide,
  isLoading,
  children,
  ...rest
}) => {
  const c = classNames(
    "jw-modal",
    {"jw-modal--prevent-hide": preventHide},
    className
  );
  let modalOnHide = preventHide ? () => {} : onHide;

  return <BootstrapModal
    className={c}
    onHide={modalOnHide}
    {...rest}
  >
    <ModalLoader show={!!isLoading}>
      {children}
    </ModalLoader>
  </BootstrapModal>;
};

export default Modal;
