type stringOrNullish = string | undefined | null | boolean;

const appendStrings = (combineString: stringOrNullish[], delimiter = ", ") => {
  let output = "";
  let addedCount = 0;
  combineString.forEach((s, i) => {
    if (!s) {
      return;
    }

    if (addedCount > 0) {
      output += delimiter;
    }

    output += s;
    addedCount += 1;
  });

  return output;
};

export default appendStrings;
