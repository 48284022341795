import React, {useEffect} from "react";
import Form from "../../../ui-components/form/Form";
import useInputValue from "../../../ui-components/form/input-helpers/useInputValue";
import usePhoneInputMutator from "../../../ui-components/form/input-helpers/usePhoneInputMutator";
import {Col, Row} from "react-bootstrap";
import FormField from "../../../ui-components/form/form-field/FormField";
import PhoneInput from "../../../ui-components/form/inputs/phone/PhoneInput";
import TimezoneSelect from "../../../ui-components/form/inputs/timezone-select/TimezoneSelect";
import ButtonRow from "../../../ui-components/button-row/ButtonRow";
import Button from "../../../ui-components/button/Button";

export interface EditProfileFormData {
  first: string,
  last: string,
  companyName?: string,
  phone?: string,
  operatingTimezone?: string,
}

interface FormEditProfileProps {
  className?: string,
  defaultValues?: EditProfileFormData,
  onSubmit: (data: EditProfileFormData) => void,
}

const EditProfileForm: React.FC<FormEditProfileProps> = ({
  onSubmit,
  defaultValues,
  ...rest
}) => {
  const [firstNameValue, firstNameValueOnChange, setFirstNameValue] = useInputValue();
  const [lastNameValue, lastNameValueOnChange, setLastNameValue] = useInputValue();
  const [companyNameValue, companyNameValueOnChange, setCompanyNameValue] = useInputValue();
  const [phoneValue, phoneValueRaw, phoneValueOnChange, setPhoneValue] = usePhoneInputMutator();
  const [operatingTimezoneValue, operatingTimezoneValueOnChange, setOperatingTimezoneValue] = useInputValue();

  useEffect(() => {
    handleResetForm();
  }, [defaultValues])

  const handleSubmit = () => {
    onSubmit({
      first: firstNameValue,
      last: lastNameValue,
      companyName: companyNameValue,
      phone: phoneValue?.formatInternational(),
      operatingTimezone: operatingTimezoneValue,
    });
  };

  const handleResetForm = () => {
    setFirstNameValue(defaultValues?.first || "");
    setLastNameValue(defaultValues?.last || "");
    setCompanyNameValue(defaultValues?.companyName || "");
    setPhoneValue(defaultValues?.phone || "");
    setOperatingTimezoneValue(defaultValues?.operatingTimezone || "");
  }

  return <Form onSubmit={handleSubmit}>
    <Row>
      <Col>
        <FormField
          label="First Name"
          formControlProps={{
            value: firstNameValue,
            onChange: firstNameValueOnChange,
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Last Name"
          formControlProps={{
            value: lastNameValue,
            onChange: lastNameValueOnChange,
            required: true,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Company Name"
          formControlProps={{
            value: companyNameValue,
            onChange: companyNameValueOnChange,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Phone"
          formControlProps={{
            as: PhoneInput,
            value: phoneValueRaw,
            onChange: phoneValueOnChange,
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <FormField
          label="Operating Timezone"
          formControlProps={{
            as: TimezoneSelect,
            onChange: operatingTimezoneValueOnChange,
            defaultValue: operatingTimezoneValue,
            placeholder: "(GMT-05:00) New York City",
          }}
        />
      </Col>
    </Row>
    <Row>
      <Col className="d-flex justify-content-end">
        <ButtonRow alignment="left">
          <Button
            className="mt-2"
            variant="outline-dark"
            type="button"
            onClick={handleResetForm}
          >
            Reset
          </Button>
          <Button
            className="mt-2"
            type="submit"
          >
            Submit
          </Button>
        </ButtonRow>
      </Col>
    </Row>
  </Form>;
};

export default EditProfileForm;
