import React from "react";
import classNames from "classnames";
import {TRIP_REQUEST_STATUS} from "../../services/trips/trip-entity";

type ColorBadgeColors = "purple" | "green" | "blue" | "yellow";

interface ColoredBadgeProps {
  color: ColorBadgeColors,
  className?: string,
}

export const getColorFromTripRequestStatus: (status: TRIP_REQUEST_STATUS) => ColorBadgeColors = (status) => {
  if (status === "requested") {
    return "blue";
  }
  else if (status === "matched") {
    return "purple";
  }
  else if (status === "quoted") {
    return "green";
  }
  else if (status === "canceled") {
    return "yellow";
  }
  else {
    return "yellow";
  }
}

const ColoredBadge: React.FC<ColoredBadgeProps> = ({
  color = "blue",
  className,
  ...rest
}) => {
  const c = classNames([
    "jw-colored-badge",
    `jw-colored-badge--${color}`,
    className,
  ]);

  return <div className={c} {...rest} />;
};

export default ColoredBadge;
