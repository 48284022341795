import React, {useState} from "react";
import dayjs from "../../../lib/dayjs";
import {Dayjs} from "dayjs";

type onChangeDateMutator = (callback: (date: Dayjs) => void, callback2: (date: string) => void) => React.ChangeEventHandler<HTMLInputElement>
const onChangeDateValue: onChangeDateMutator = (onSetValue, onSetRawValue) => {
  return (e) => {
    onSetValue(dayjs(e.currentTarget.value));
    onSetRawValue(e.currentTarget.value);
  };
};

const dayJsToRawDateInputString = (day?: Dayjs) => {
  if (!day) {
    return "";
  }

  return day.format("YYYY-MM-DD");
};

const useDateInputMutator = (defaultValue: Dayjs = dayjs()) => {
  const [rawValue, setRawValue] = useState<string | undefined>(dayJsToRawDateInputString(defaultValue));
  const [value, setValue] = useState<Dayjs | undefined>(defaultValue);

  const setDateInputValue = (rawValue: string) => {
    setRawValue(rawValue);
    setValue(rawValue ? dayjs(rawValue) : undefined);
  }

  return [
    value,
    rawValue || "",
    onChangeDateValue(setValue, setRawValue),
    setDateInputValue,
  ] as const;
};

export default useDateInputMutator;
