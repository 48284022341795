import {useContext} from "react";
import AuthContext from "../auth-context";
import {logout, login as authServiceLogin, signUp as authServiceSignUp} from "../auth-service";
import {SignUpFormData} from "../../../app/account/sign-up/SignUpForm";

const useAuth = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    username,
    userEmail,
    userEmailVerified,
    changeUserEmail,
    verifyEmail,
    resendVerification,
  } = useContext(AuthContext);

  const goToLogout: () => void = () => {
    logout();
    setIsAuthenticated(false);
  };

  const login = (email: string, password: string) => {
    return authServiceLogin(email, password)
    .then(u => {
      setIsAuthenticated(!!u);
    });
  };

  const signUp = (email: string, password: string, data: SignUpFormData) => {
    return authServiceSignUp(email, password, data);
  };

  return {
    goToLogout,
    login,
    signUp,
    isAuthenticated,
    username,
    userEmail,
    userEmailVerified,
    changeUserEmail,
    resendVerification,
    verifyEmail,
  };
};

export default useAuth;
