import React from "react";
import {CondensedCol, CondensedRow} from "../../../ui-components/grid/condensed-grid/condensed-grid";
import FormField from "../../../ui-components/form/form-field/FormField";
import AirportSelect from "../../../ui-components/form/inputs/airport-select/AirportSelect";
import Select from "../../../ui-components/form/inputs/select/Select";
import FormFieldGroup from "../../../ui-components/form/form-field-group/form-field-group";

const withinOptions = [
  {value: "0", label: "exact"},
  {value: (2 * 60).toString(), label: "a few hours"},
  {value: (12 * 60).toString(), label: "same day"},
  {value: (24 * 60).toString(), label: "one day"},
  {value: (48 * 60).toString(), label: "two days"}
];

interface TripRequestFlightInputsProps {
  fromValue: string,
  fromOnChange: React.ChangeEventHandler,
  toValue: string,
  toOnChange: React.ChangeEventHandler,
  tripDateValue: string,
  tripDateOnChange: React.ChangeEventHandler,
  tripTimeValue: string,
  tripTimeOnChange: React.ChangeEventHandler,
  withinValue: string,
  withinOnChange: React.ChangeEventHandler,
}

const TripRequestFlightInfo: React.FC<TripRequestFlightInputsProps> = ({
  fromValue,
  fromOnChange,
  toValue,
  toOnChange,
  tripDateValue,
  tripDateOnChange,
  tripTimeValue,
  tripTimeOnChange,
  withinValue,
  withinOnChange,
}) => {
  return <CondensedRow>
    <CondensedCol className="d-flex justify-content-center">
      <FormFieldGroup hasLabels={true}>
        <FormField
          label="From Airport"
          formControlProps={{
            as: AirportSelect,
            onChange: fromOnChange,
            value: fromValue,
            placeholder: "ex: KJFK",
            required: true,
          }}
          compressed={true}
          icon="takeoff"
        />
        <FormField
          label="To Airport"
          formControlProps={{
            as: AirportSelect,
            onChange: toOnChange,
            value: toValue,
            placeholder: "ex: KJFK",
            required: true,
          }}
          compressed={true}
          icon="landing"
        />
        <FormField
          label="Trip Date"
          formControlProps={{
            type: "date",
            onChange: tripDateOnChange,
            value: tripDateValue,
            required: true,
          }}
          formControlFeedbackProps={{
            validMessage: "That's the best time to get matches. You're client will love you.",
            invalidMessage: "Can't make a match without a trip date.",
          }}
          compressed={true}
          icon="calendar"
        />
        <FormField
          label={"Local Departure Time"}
          formControlProps={{
            type: "time",
            onChange: tripTimeOnChange,
            value: tripTimeValue,
            required: true,
          }}
          formControlFeedbackProps={{
            validMessage: "That's the best time to get matches. You're client will love you.",
            invalidMessage: "Can't make a match without a trip date.",
          }}
          compressed={true}
          icon="clock"
        />
        <FormField
          label="Departure Flexibility?"
          formControlProps={{
            as: Select,
            onChange: withinOnChange,
            options: withinOptions,
            value: withinValue,
            required: false,
          }}
          compressed={true}
        />
      </FormFieldGroup>
    </CondensedCol>
  </CondensedRow>;
}

export default TripRequestFlightInfo;
