import React from "react";
import {AuthContextProvider} from "../../services/auth/auth-context";
import {AlertsContextProvider} from "../../services/alerts/alerts-context";
import {LoadingContextProvider} from "../../services/loading/loading-context";
import {ModalContextProvider} from "../../ui-components/modal/context/modal-stack-context";

const AppGlobalContextWrapper: React.FC = ({...rest}) => {
  return <AlertsContextProvider>
    <LoadingContextProvider>
      <AuthContextProvider>
        <ModalContextProvider>
          <div {...rest} />
        </ModalContextProvider>
      </AuthContextProvider>
    </LoadingContextProvider>
  </AlertsContextProvider>;
};

export default AppGlobalContextWrapper;
