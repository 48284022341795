import React, {useState} from "react";
import AlertsContainer from "../../ui-components/alerts/alerts-container";
import Alert, {AlertType} from "../../ui-components/alerts/alert";
import uniqueId from "lodash/uniqueId";


type AlertPopup = {
  id: string,
  type: AlertType,
  children: React.ReactNode,
}

export interface AlertContextInterface {
  popSuccess: (message: React.ReactNode) => void,
  popError: (message: React.ReactNode) => void,
  popNotification: (message: React.ReactNode) => void,
}

export const AlertsContext = React.createContext<AlertContextInterface>({
  popSuccess: () => {},
  popError: () => {},
  popNotification: () => {},
});

export const AlertsContextProvider: React.FC = ({children}) => {
  const [alertPopups, setAlertPopups] = useState<AlertPopup[]>([]);

  const alertPopupHasExited = (alertToBeDeleted: AlertPopup) => {
    setAlertPopups(currentState =>
      currentState.filter((a) => a.id !== alertToBeDeleted.id),
    );
  };

  const popAlert = (children: React.ReactNode, type: AlertType) => {
    const newAlertId = uniqueId("alert-");

    setAlertPopups(currentState => {
      return [
        ...currentState,
        {
          id: newAlertId,
          type,
          children,
        },
      ];
    });
  };

  const popSuccess = (message: React.ReactNode) => {
    popAlert(message, "success");
  };

  const popError = (message: React.ReactNode) => {
    popAlert(message, "error");
  };

  const popNotification = (message: React.ReactNode) => {
    popAlert(message, "notification");
  };


  return <AlertsContext.Provider value={{
    popError,
    popSuccess,
    popNotification,
  }}>
    {children}
    <AlertsContainer>
      {alertPopups.map(alert => (
        <Alert
          key={alert.id}
          type={alert.type}
          onExited={() => alertPopupHasExited(alert)}
        >
          {alert.children}
        </Alert>
      ))}
    </AlertsContainer>
  </AlertsContext.Provider>;
};

export default AlertsContext;



