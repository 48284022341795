import {useContext} from "react";
import ModalStackContext, {DismissOptions} from "../context/modal-stack-context";
import {ModalIdContext} from "../context/modal-id-context";

/*
Pass through hook to dismiss modal functionality.
Modal to be dismissed defaults to the current context.
 */
interface DismissHookOptions extends DismissOptions {
  id?: string,
}

const useDismissModal = () => {
  const modalId = useContext(ModalIdContext)
  const {dismiss} = useContext(ModalStackContext);
  return (options?: DismissHookOptions) => {
    const {id, ...rest} = options || {};
    dismiss(id || modalId, rest);
  }
}

export default useDismissModal;
