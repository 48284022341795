import React from "react";
import {Alert} from "react-bootstrap";
import content from "../../content/content";

interface ListErrorProps {
  title?: string,
  message?: string,
  className?: string,
}

const ListError: React.FC<ListErrorProps> = ({
  title,
  message,
  ...rest
}) => {
  return <Alert variant="danger">
    <Alert.Heading>
      {title || content.alerts.defaultInlineAlertTitle}
    </Alert.Heading>
    <p>
      {message || content.alerts.defaultInlineAlertMessage}
    </p>
  </Alert>;
};

export default ListError;
