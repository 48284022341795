import classNames from "classnames";
import React from "react";

interface ComponentProps {
  className?: string,
}

const ListItem: React.FC<ComponentProps> = ({className, ...rest}) => {
  const c = classNames("jw-list__item", className)
  return <div className={c} {...rest} />;
};

export default ListItem;
