import dayjs, {Dayjs} from "dayjs";
import appendStrings from "../../utl/string/append-strings";

export type TRIP_REQUEST_STATUS = "requested" | "quoted" | "matched" | "canceled" | "unknown";

interface AirportData {
  country?: string,
  full_name?: string,
  icao?: string,
  name?: string,
  state?: string,
  latitude_deg?: number,
  longitude_deg?: number,
}

interface TripFlight {
  fromAirportId: string,
  fromAirportData: AirportData,
  toAirportId: string,
  toAirportData: AirportData,
  // To-do add date and time
  date: Dayjs,
  departureInfo: {
    dateTime?: Dayjs,
    flexibilityInMinutes?: number,
  },
}

class TripEntity {
  static friendlyTripDate: (date: Dayjs) => (string);
  static friendlyAirportName: (airportData: AirportData, includeIcao: boolean) => (string);

  private readonly _dbData: Record<string, any>;
  public flights: TripFlight[];
  public gsi1sk: string;
  public minAircraftSize: string;
  public passengerCount: number;
  public clientType: string;
  public matchId: string;
  public status: TRIP_REQUEST_STATUS;
  public requestId: string;
  public dynamoFromAirport: string; // Used to reference special indexes in DynamoDB
  public dynamoCompositeSort: string; // Used to reference special indexes in DynamoDB

  constructor(dbData: Record<string, any>) {
    this._dbData = dbData;
    this.gsi1sk = dbData.gsi1sk;
    this.minAircraftSize = dbData.min_aircraft_size;
    this.passengerCount = dbData.passenger_count;
    this.clientType = dbData.client_type;
    this.matchId = dbData.match_id;
    this.requestId = dbData.request_id;
    this.status = this.getStatus(dbData);
    this.requestId = dbData.request_id;

    // flights is implemented as an array for when we support round trips
    this.flights = [{
      fromAirportId: dbData.from_airport,
      fromAirportData: dbData.from_airport_info || {},
      toAirportId: dbData.to_airport,
      toAirportData: dbData.to_airport_info || {},
      date: dayjs(dbData.trip_date),
      departureInfo: dbData.departure_info ?
        {
          dateTime: dayjs(dbData.departure_info.datetime).utc(false),
          flexibilityInMinutes: dbData.departure_info.flexibility_in_minutes,
        } : {},
    }];

    this.dynamoFromAirport = this.flights[0] && this.flights[0].fromAirportId;
    this.dynamoCompositeSort = dbData.composite_sort;
  }

  containsSearchTerm(searchTerm: string) {
    let entityDetails = "";
    this.flights.forEach(flight => {
      entityDetails += `|
        ${TripEntity.friendlyAirportName(flight.fromAirportData, true)}
        |${TripEntity.friendlyAirportName(flight.toAirportData, true)}
        |${TripEntity.friendlyTripDate(flight.date)}
      `;
    });

    return entityDetails.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
  }

  toDbData() {
    return this._dbData;
  }

  getFlight(index: number = 0) {
    return this.flights[index];
  }

  private getStatus(dbData: Record<string, any>): TRIP_REQUEST_STATUS {
    const matchStatus = dbData.match_status;

    if (!matchStatus) {
      return "requested";
    }

    if (matchStatus === "quoted" || matchStatus === "quotted")
      return "quoted";
    else if (matchStatus === "matched")
      return "matched";
    else if (matchStatus === "canceled")
      return "canceled";
    else {
      console.warn("unknown match status", matchStatus);
      return "unknown";
    }
  }
}

TripEntity.friendlyAirportName = (airportData, includeIcao) => {
  if (airportData?.name) {
    return appendStrings([
      includeIcao && airportData.icao,
      airportData.name,
      airportData.state,
      airportData.country,
    ]);
  } else {
    return "";
  }
};

TripEntity.friendlyTripDate = (date: Dayjs) => {
  if (date) {
    return date.format("MM/DD/YYYY");
  } else {
    return "unknown";
  }
};

export default TripEntity;
