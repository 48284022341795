import React, {useEffect} from "react";
import {RouteProps, useHistory, useLocation} from "react-router-dom";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import useAuth from "../../services/auth/hooks/useAuth";
import {Col, Row} from "react-bootstrap";
import Form from "../../ui-components/form/Form";
import FormField from "../../ui-components/form/form-field/FormField";
import useInputValue from "../../ui-components/form/input-helpers/useInputValue";
import useAlerts from "../../services/alerts/hooks/useAlerts";
import {LoginPageRoute} from "./LoginPage";
import CardContainer from "../../ui-components/card-container/CardContainer";
import Button from "../../ui-components/button/Button";

export const EmailVerifyPageRoute = "/route-name";

const Page: React.FC = () => {
  const {verifyEmail, resendVerification} = useAuth();
  const {popError, popSuccess} = useAlerts();
  const history = useHistory();
  const location = useLocation();

  // @ts-ignore
  const userEmail = location.state?.email || "";
  const [email, emailOnChange] = useInputValue(userEmail);
  const [code, codeOnChange] = useInputValue();

  const handleSubmit = async () => {
    try {
      await verifyEmail(email, code);
      popSuccess("Email verified. You may now log into your account.");
      history.push(LoginPageRoute, {
        email: userEmail,
      });
    } catch (e) {
      if (e instanceof Error)
        popError(e.message);
    }
  };

  const handleRequestVerification = () => {
    try {
      resendVerification(email);
    } catch (e) {
      if (e instanceof Error)
        popError(e.message);
    }
  };

  return <RouteGuardSwitcher routeGuard="landing">
    <PageLayout
      navType="app"
    >
      <CardContainer type={"white"}>
        <h1>Please Verify Your Email</h1>
        <p>You can find your code in your email inbox {userEmail}.</p>
        <Form onSubmit={handleSubmit}>
          <FormField
            label="Email"
            formControlProps={{
              value: email,
              onChange: emailOnChange,
              required: true,
            }}
          />
          <FormField
            label="Verification Code"
            formControlProps={{
              value: code,
              onChange: codeOnChange,
              required: true,
            }}
          />
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              <Button
                className="pl-0"
                variant="link"
                onClick={handleRequestVerification}
              >
                Request Another Code
              </Button>
              <Button className="mt-2" type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </CardContainer>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const EmailVerifyPage: RouteProps = {
  component: Page,
  path: EmailVerifyPageRoute,
  exact: true,
};

export default EmailVerifyPage;
