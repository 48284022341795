import React from "react";
import {RouteProps} from "react-router-dom";
import RouteGuardSwitcher from "../../app/route-gaurds/RouteGuardSwitcher";
import PageLayout from "../../ui-components/page-layouts/PageLayout";
import SignUp from "../../app/account/sign-up/SignUp";
import CardContainer from "../../ui-components/card-container/CardContainer";

/* This route must be configured in aws cognito login redirect */
export const SignUpPageRoute = "/sign-up";

const Page: React.FC = () => {
  return <RouteGuardSwitcher routeGuard="landing">
    <PageLayout
      navType="landing"
      pageWidth="form"
    >
      <CardContainer type="white">
        <h1>Create Account</h1>
        <p>Thank you for your interest in Jet Wire. Please enter the following information to create an account.</p>
        <SignUp />
      </CardContainer>
    </PageLayout>
  </RouteGuardSwitcher>;
};

const ProcessLoginPage: RouteProps = {
  component: Page,
  path: SignUpPageRoute,
  exact: true,
};

export default ProcessLoginPage;
