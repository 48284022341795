import {useContext, useEffect, useState} from "react";
import AuthContext from "../../../services/auth/auth-context";
import {UserProfile} from "../../../services/user-profile/type";
import {getCurrentUserProfile} from "../../../services/user-profile/user-profile-service";
import useAlerts from "../../../services/alerts/hooks/useAlerts";
import useGlobalLoading from "../../../services/loading/hooks/useGlobalLoading";

const useLoadUserProfile = () => {
  const {username} = useContext(AuthContext);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const {popError} = useAlerts();
  const {startLoading, stopLoading} = useGlobalLoading()

  useEffect(() => {
    (async () => {
      if (username) {
        const loaderId = startLoading();
        try {
          setUserProfile(
            await getCurrentUserProfile(),
          );
        } catch (e) {
          if (e instanceof Error)
            popError(e.message);
        }
        stopLoading(loaderId);
      } else {
        setUserProfile(null);
      }
    })();
  }, [username]);

  return userProfile;
};

export default useLoadUserProfile;
