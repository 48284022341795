import React from "react";
import TripEntity from "../../services/trips/trip-entity";
import useLoadMatch from "../../services/trips/hooks/useLoadMatch";
import CardContainer from "../../ui-components/card-container/CardContainer";
import InlineLoader from "../../ui-components/loaders/inline-loader/InlineLoader";
import DetailsWithIcon from "./details/DetailsWithIcon";
import DetailsForQuote from "./details/DetailsForQuote";

interface TripMatchDetailsProps {
  trip: TripEntity,
  className?: string,
}

const TripMatchDetails: React.FC<TripMatchDetailsProps> = ({trip, ...rest}) => {
  const {hasMatch, match, loading} = useLoadMatch(trip);

  let showDetails;
  if (loading)
    showDetails = "loading";
  else if (!hasMatch)
    showDetails = "match-pending";
  else if(!match?.hasBeenQuoted)
    showDetails = "matched-awaiting-quote";
  else
    showDetails = "match-quoted";

  return <CardContainer type="white" {...rest}>
    {showDetails === "loading" && <InlineLoader show={loading}/>}
    {showDetails === "match-pending" && (
      <DetailsWithIcon icon="server" title="Request Submitted">
        Algorithm actively searching for a match.
      </DetailsWithIcon>
    )}
    {showDetails === "matched-awaiting-quote" && (
      <DetailsWithIcon icon="check" title="Algorithm Identified a Match">
        Quote pending... Stay close for now.
      </DetailsWithIcon>
    )}
    {showDetails === "match-quoted" && match && (
      <DetailsForQuote match={match} trip={trip} />
    )}
  </CardContainer>;
};

export default TripMatchDetails;
