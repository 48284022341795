import React from "react";
import {Modal} from "react-bootstrap";
import useDismissModal from "../../../../ui-components/modal/hooks/useDismissModal";
import useInputValue from "../../../../ui-components/form/input-helpers/useInputValue";
import Form from "../../../../ui-components/form/Form";
import FormField from "../../../../ui-components/form/form-field/FormField";
import ModalHeader from "../../../../ui-components/modal/components/ModalHeader";
import useAlerts from "../../../../services/alerts/hooks/useAlerts";
import useLoadingModal from "../../../../ui-components/modal/hooks/useLoadingModal";
import {changeUserPassword} from "../../../../services/auth/auth-service";
import Button from "../../../../ui-components/button/Button";

const EmailEditModal: React.FC = () => {
  const dismissModal = useDismissModal();
  const [currentPassword, currentPasswordOnChange] = useInputValue();
  const [newPassword, newPasswordOnChange] = useInputValue();
  const {popError, popSuccess} = useAlerts();
  const setLoading = useLoadingModal();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await changeUserPassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      });

      dismissModal();
      popSuccess(`You have successfully changed your password.`)
    } catch (e) {
      if (e instanceof Error)
        popError(e.message);
    }
    setLoading(false);
  }

  return <>
    <Form onSubmit={handleSubmit}>
      <ModalHeader closeButton className="align-items-center">
        <Modal.Title className="d-flex justify-content-between w-100 align-items-center">
          Change Your Password
        </Modal.Title>
      </ModalHeader>

      <Modal.Body>
        <FormField
          label="Current Password"
          formControlProps={{
            type: "password",
            value: currentPassword,
            onChange: currentPasswordOnChange,
            required: true,
          }}
        />
        <FormField
          label="New Password"
          formControlProps={{
            type: "password",
            value: newPassword,
            onChange: newPasswordOnChange,
            required: true,
          }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button type="submit">
          Submit
        </Button>
      </Modal.Footer>
    </Form>
  </>;
};

export default EmailEditModal;
