import {useCallback, useEffect, useState} from "react";
import {getTrips, TripListKey} from "../trips-service";
import TripEntity from "../trip-entity";
import {TRIP_QUERY_LIMIT} from "../../../config";

interface queryTripState {
  isLoading: boolean,
  fullData?: TripEntity[],
  lastEvaluatedKey?: string | null,
  error?: Error,
}

interface userLoadTripsHook {
  isLoading: boolean,
  trips: TripEntity[],
  setSearchTerm: (searchTerm: string) => void,
  error?: Error,
  refreshTrips: () => void,
  moreAvailable: boolean,
  loadMore: () => Promise<void>,
}

const useLoadTrips: (tripListKey: TripListKey) => userLoadTripsHook = (tripListKey) => {
  const [displayData, setDisplayData] = useState<TripEntity[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [queryState, setQueryState] = useState<queryTripState>({
    isLoading: false,
  });

  const handleLoadTrips = useCallback(async (lastEvalKey?: string | null) => {
    try {
      setQueryState(current => ({
        ...current,
        isLoading: true,
      }));

      const isLoadingMore = Boolean(lastEvalKey);

      // If not loading more set the limit to what's displayed to act as a full refresh.
      const limit = isLoadingMore ? TRIP_QUERY_LIMIT : (displayData.length || TRIP_QUERY_LIMIT);

      const {trips, lastEvaluatedKey} = await getTrips({
        limit,
        key: tripListKey,
        ...(isLoadingMore && {lastEvaluatedKey: lastEvalKey})
      });
      setQueryState(current => ({
        isLoading: false,
        fullData: isLoadingMore ? [...(current.fullData || []), ...trips] : trips,
        lastEvaluatedKey,
      }));
    } catch (e) {
      if (e instanceof Error) {
        setQueryState({
          isLoading: false,
          error: e,
        });
      }
    }
  }, [displayData]);

  useEffect(() => {
    (async () => {
      await handleLoadTrips();
    })();
  }, [tripListKey, refreshCount]);

  useEffect(() => {
    const fullData = queryState?.fullData || [];
    setDisplayData(
      fullData.filter(trips => trips.containsSearchTerm(searchTerm)),
    );
  }, [queryState, searchTerm]);

  return {
    isLoading: queryState.isLoading,
    setSearchTerm,
    trips: displayData,
    refreshTrips: () => {
      setRefreshCount(currentCount => currentCount + 1);
    },
    moreAvailable: Boolean(queryState.lastEvaluatedKey),
    loadMore: async () => {
      try {
        console.log("HIT");
        await handleLoadTrips(queryState.lastEvaluatedKey);
      } catch (e) {
        if (e instanceof Error) {
          setQueryState({
            isLoading: false,
            error: e,
          });
        }
      }
    },
  };
};

export default useLoadTrips;
