import React from "react";
import {Route, RouteProps, Router, Switch} from "react-router-dom";
import HomePage, {HomePageRoute} from "../../pages/landing/HomePage";
import {createBrowserHistory} from "history";
import DashboardPage, {DashboardPageRoute} from "../../pages/portal/DashboardPage";
import PastTripRequestsPage from "../../pages/portal/PastTripRequestsPage";
import AppGlobalContextWrapper from "./AppGlobalContextWrapper";
import AccountPage from "../../pages/portal/AccountPage";
import SignUpPage from "../../pages/landing/SignUpPage";
import LoginPage from "../../pages/landing/LoginPage";
import ForgotPasswordPage from "../../pages/landing/ForgotPasswordPage";
import ForgotPasswordSecondPage from "../../pages/landing/ForgotPasswordSecondPage";
import EmailVerifyPage from "../../pages/landing/EmailVerifyPage";
import TripRequestPage from "../../pages/portal/TripRequestPage";

/* TODO LOAD GOOGLE MAP HERE AND PASS DOWN SCRIPTLOADED */

const history = createBrowserHistory();

const routes: RouteProps[] = [
  AccountPage,
  DashboardPage,
  EmailVerifyPage,
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  ForgotPasswordSecondPage,
  PastTripRequestsPage,
  SignUpPage,
  TripRequestPage,
];

export const AUTENTICATED_LANDING_PAGE = DashboardPageRoute;
export const UNAUTENTICATED_LANDING_PAGE = HomePageRoute;

function App() {
  return (
    <Router history={history}>
      <AppGlobalContextWrapper>
        <Switch>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
        </Switch>
      </AppGlobalContextWrapper>
    </Router>
  );
}

export default App;
