import React from "react";
import classNames from "classnames"
import "./button-row.scss";

interface ButtonRowProps {
  className?: string,
  alignment: "left" | "center" | "right",
}

/*
  ButtonRow
  A container to wrap multiple <Button /> components and give them consistent padding.
 */
const ButtonRow: React.FC<ButtonRowProps> = ({children, alignment, className}) => {
  const baseClass = "jw-button-row";
  const c = classNames([
    baseClass,
    `${baseClass}--${alignment}`,
    className
  ]);
  return <div className={c}>
    {children}
  </div>;
};

export default ButtonRow;
