import React from "react";
import List from "../../../ui-components/list/List";
import ListItem from "../../../ui-components/list/ListItem";
import useAuth from "../../../services/auth/hooks/useAuth";
import usePresentModal from "../../../ui-components/modal/hooks/usePresentModal";
import EditEmailModal from "./modals/EditEmailModal";
import EditPasswordModal from "./modals/EditPasswordModal";
import Button from "../../../ui-components/button/Button";

interface ComponentProps {
  className?: string,
}

const EditableAccountInfo: React.FC<ComponentProps> = ({...rest}) => {
  const {userEmail} = useAuth();
  const presentEditEmail = usePresentModal(<EditEmailModal/>);
  const presentEditPassword = usePresentModal(<EditPasswordModal/>);

  return <List {...rest}>
    <ListItem>
      <div className="account-info-item">
        <div>
          <div className="account-info-item__label">Email Address</div>
          <p className="account-info-item__description">
            Your Email Address is <b>{userEmail}</b>
          </p>
        </div>
        <div>
          <Button
            variant="outline-dark"
            onClick={presentEditEmail}
          >
            Change
          </Button>
        </div>
      </div>
    </ListItem>
    <ListItem>
      <div className="account-info-item">
        <div>
          <div className="account-info-item__label">Password</div>
        </div>
        <div>
          <Button
            variant="outline-dark"
            onClick={presentEditPassword}
          >
            Change
          </Button>
        </div>
      </div>
    </ListItem>
  </List>;
};

export default EditableAccountInfo;
