import React from "react";
import MatchEntity from "../../../services/trips/match-entity";
import {formatCurrency} from "../../../utl/string/currency";
import InfoDatetime from "../../../ui-components/info-datetime/InfoDatetime";
import TripEntity from "../../../services/trips/trip-entity";
import {Col, Row} from "react-bootstrap";
import Button from "../../../ui-components/button/Button";
import ButtonRow from "../../../ui-components/button-row/ButtonRow";
import Info from "../../../ui-components/info/Info";
import CardContainer from "../../../ui-components/card-container/CardContainer";

interface DetailsForQuoteProps {
  className?: string,
  match: MatchEntity,
  trip: TripEntity,
}

const DetailsForQuote: React.FC<DetailsForQuoteProps> = ({match, trip, ...rest}) => {
  const departureTime = trip.getFlight()?.departureInfo.dateTime;
  return <div {...rest}>
    <Row>
      <Col>
        <p className="mb-3"><b>Best Match Price: </b>{match.quote && formatCurrency(match.quote)}</p>
        <h3 className="h2 mb-3">{match.aircraft}</h3>
        {departureTime && <InfoDatetime day={departureTime} />}
      </Col>
      <Col>
        <CardContainer type="gray" smPadding>
          <Info className="w-100 pt-3 pb-3" label="Amenities" alignment={"left"}>
            {match.amenities}
          </Info>
        </CardContainer>
      </Col>
    </Row>
    <Row>
      <Col>
        <ButtonRow alignment={"left"}>
          {(match.attachments || []).map(a => (
            <Button key={a.url} href={a.url} target="_blank" variant="dark">View {a.label}</Button>
          ))}
        </ButtonRow>
      </Col>
      <Col>
        <ButtonRow alignment={"right"}>
          <Button disabled={true} variant="dark">Approve Match</Button>
          <Button variant="link" disabled={true}>Decline Match</Button>
        </ButtonRow>
      </Col>
    </Row>

  </div>;
};

export default DetailsForQuote;
