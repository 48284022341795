import {useState} from "react";

/*
Simple hook to increment a count. Useful for refreshing things
*/

const useIncrement: () => [number, () => void] = () => {
  const [count, setCount] = useState<number>(0);

  return [
    count,
    () => setCount(current => current + 1),
  ];
}

export default useIncrement;
