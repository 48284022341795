import React from "react";
import {ModalHeaderProps as BootstrapModalHeaderProps} from "react-bootstrap/ModalHeader";
import {Modal} from "react-bootstrap";
import classNames from "classnames";

interface ModalHeaderProps extends BootstrapModalHeaderProps{
  className?: string,
}

/* Wraps Bootstrap's Modal.Header component to provide consistent classes and close button by default */
const ModalHeader: React.FC<ModalHeaderProps> = ({className, ...rest}) => {
  const c = classNames( "jw-modal__header", className);
  return <Modal.Header
    className={c}
    closeButton // close button should be shown by default
    {...rest}
  />;
};

export default ModalHeader;
