import React from "react";
import PortalRouteGuard from "./guards/PortalRouteGuard";
import LandingRouteGuard from "./guards/LandingRouteGuard";

export type RouteGuard = "portal" | "landing";

interface RouteGuardSwitcherProps {
  routeGuard: RouteGuard,
}

const RouteGuardSwitcher: React.FC<RouteGuardSwitcherProps> = ({routeGuard, children}) => {
  if (routeGuard === "portal") {
    return <PortalRouteGuard children={children} />
  }
  if (routeGuard === "landing") {
    return <LandingRouteGuard children={children} />
  }
  return <>
    {children}
  </>;
};

export default RouteGuardSwitcher;
