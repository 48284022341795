/*
  useTimezoneOptions will return options that work with react-select. Here's information about
  react-select: https://react-select.com/home
*/
import getHearAbouts from "../../../../utl/hear-about/get-hear-about-options";
import {useEffect, useState} from "react";
import useAlerts from "../../../../services/alerts/hooks/useAlerts";

export type HearAboutOptions = { label: string, value: string }[];

const useHearAboutOptions = () => {
  const [options, setOptions] = useState<HearAboutOptions>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {popError} = useAlerts();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const HearAboutOptions = await getHearAbouts();
        setOptions(HearAboutOptions);
      } catch (e) {
        if (e instanceof Error)
          popError(e.message);
      }
      setIsLoading(false);
    })()
  }, [popError]);

  return {
    options,
    isLoading,
  };
}

export default useHearAboutOptions;
