const content = {
  alerts: {
    defaultInlineAlertTitle: "An error occurred.",
    defaultInlineAlertMessage: "Please try again later.",
  },
  navbarTitle: "Jet Wire",
  loginButton: "Login",
  signUpButton: "Sign Up",
  logoutButton: "Log Out",
  routeNames: {
    dashboard: "Trips",
    tripRequest: "Trip Request",
    pastRequests: "Past Trip Requests",
    account: "Profile",
  },
  formSubmissionText: "Send Request",
};

export default content;
