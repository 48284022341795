import React from "react";
import {useHistory} from "react-router-dom";
import content from "../../../content/content";
import ButtonRow from "../../button-row/ButtonRow";
import {LoginPageRoute} from "../../../pages/landing/LoginPage";
import {SignUpPageRoute} from "../../../pages/landing/SignUpPage";
import Navbar from "../../nav/Navbar";
import Button from "../../button/Button";

const LandingNavbar: React.FC = () => {
  const history = useHistory();

  return <Navbar
    title={content.navbarTitle}
    collapsable={false}
  >
    <ButtonRow alignment="right">
      <Button variant="outline-dark" onClick={() => history.push(LoginPageRoute)} size="sm">
        {content.loginButton}
      </Button>
      <Button onClick={() => history.push(SignUpPageRoute)} size="sm">
        {content.signUpButton}
      </Button>
    </ButtonRow>
  </Navbar>
}

export default LandingNavbar;
